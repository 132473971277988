import React, { useState, useEffect } from 'react'
import { Box, TextField, Alert } from '@mui/material'
import Icon from '../../../client/icons/Icon'
import JncButton from '../../../client/components/global/JncButton'
import { apiService } from '../../../../common/apiCallService'
import { useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'

const JunoVerifyEmail = ({
    title,
    onClick,
    onBack,
    email,
    setRegistrationData,
}) => {
    const [otp, setOtp] = useState(Array(6).fill(''))
    const [error, setError] = useState(false)
    const api_url = useSelector((state) => state.config.api_url)
    const [snackbarText, setSnackbarText] = useState('')
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (otp.filter((item) => item === '').length > 0) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [otp])

    const handleChange = (e, index) => {
        const { value } = e.target

        if (!/^\d*$/.test(value)) {
            return
        }
        const newOtp = [...otp]
        newOtp[index] = e.target.value
        setOtp(newOtp)

        if (e.target.value && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus()
        }
    }

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            document.getElementById(`otp-input-${index - 1}`).focus()
        }
    }

    const handlePaste = (e) => {
        e.preventDefault()
        const pastedValue = e.clipboardData.getData('Text')
        if (pastedValue) {
            if (!/^\d*$/.test(pastedValue)) {
                setError('Please enter a valid otp')
                return
            }
        }
        if (pastedValue.length === otp.length) {
            const newOtp = pastedValue.split('').map((char) => char.trim())
            setOtp(newOtp)
        }
    }

    const onSubmit = () => {
        setLoading(true)
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/verify-email',
            {
                clientEmail: email,
                verificationCode: otp.join(''),
            },
            async (data) => {
                setRegistrationData(data)
                setLoading(false)
                setError(false)
                onClick()
            },
            (err) => {
                setLoading(false)
                setError(err)
                console.log(err)
            }
        )
    }

    const onResend = () => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/resend-verification-code',
            {
                clientEmail: email,
            },
            async (data) => {
                setSnackbarText(data?.success)
            },
            (err) => {
                setError(err)
                console.log(err)
            }
        )
    }

    return (
        <div className="md:w-[460px] w-full h-full md:max-h-[482px] md:h-fit rounded-lg bg-white">
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={!!snackbarText}
                onClose={() => setSnackbarText('')}
                autoHideDuration={4000}
            >
                <Alert
                    onClose={() => setSnackbarText('')}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {snackbarText}
                </Alert>
            </Snackbar>
            <div className="px-[14px] py-[22px] border-b-[1px] gap-3 hidden md:flex">
                <span onClick={onBack} className="self-center cursor-pointer">
                    <Icon id="arrowLeft" />
                </span>
                <p className="text-[#18181B] font-semibold text-2xl ">
                    {title}
                </p>
            </div>
            <p className="text-lg font-medium mt-[22px] text-center text-[#70707B]">
                We sent it to <span className="text-[#18181B]">{email}</span>
            </p>
            {error && (
                <div className="border-[1px] border-[#F97066] mx-[38px] h-[44px] mt-8 rounded-lg flex items-center justify-center bg-[#FEE4E2]">
                    <p className="text-[#7A271A] text-base text-center font-medium">
                        {error || 'Invalid verification code.'}
                    </p>
                </div>
            )}
            <Box
                display="flex"
                justifyContent="center"
                className="mt-8 verify-input h-[60px] md:h-[70px]"
            >
                {otp.map((digit, index) => (
                    <div className="flex">
                        <div
                            className={`border-[1px] rounded-lg ${
                                error && digit === ''
                                    ? 'border-red-500'
                                    : 'border-[#D1D1D6]'
                            } sm:mx-[6px] mx-1 text-[48px]`}
                        >
                            <TextField
                                key={index}
                                id={`otp-input-${index}`}
                                value={digit}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                                variant="outlined"
                                className="sm:w-[50px] w-[42px] h-[60px] md:h-[70px]"
                                placeholder="0"
                                inputProps={{
                                    maxLength: 1,
                                }}
                                sx={{
                                    '& .MuiInputBase-input::placeholder': {
                                        color: error ? 'red' : 'gray', // Change placeholder color conditionally
                                    },
                                }}
                            />
                        </div>
                        {index === 2 && (
                            <div className="text-[#D1D1D6] text-[60px] w-[30px] self-center text-center">
                                -
                            </div>
                        )}
                    </div>
                ))}
            </Box>
            <div className="w-full mt-8 px-4">
                <JncButton
                    text="Submit"
                    className="w-full"
                    onClickCall={onSubmit}
                    loading={loading}
                    disabled={disabled}
                />
                <div className="text-[#51525C] mt-4 flex justify-center text-[14px] mb-[22px]">
                    <p>Didn’t received the code?</p>
                    <span
                        className="text-[#1570EF] ml-1 cursor-pointer"
                        onClick={onResend}
                    >
                        Resend code
                    </span>
                </div>
            </div>
        </div>
    )
}

export default JunoVerifyEmail
