import React from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const PasswordField = ({
    label,
    value,
    name,
    onChange,
    showPassword,
    toggleShowPassword,
    error,
    placeholder,
}) => (
    <div className={`jncAuth__field ${error ? 'jncAuth__fieldError' : ''}`}>
        <p className="jncAuth__field-label">{label}</p>
        <div className="flex items-center relative">
            <input
                type={showPassword ? 'text' : 'password'}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                name={name}
                className="w-full"
            />
            <button
                type="button"
                onClick={toggleShowPassword}
                className="absolute right-2 text-gray-500"
                aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
        </div>
    </div>
)

export default PasswordField
