import React from 'react'

export default function JncAuthTitle({ title, subtitle }) {
    return (
        <React.Fragment>
            <p className="jncAuth__title">{title}</p>
            {subtitle && <p className='jncAuth__subtitle'>{subtitle}</p>}
        </React.Fragment>
    )
}
