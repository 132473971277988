import React, { useState, useEffect } from 'react'
import { Typography, FormControl, Select, MenuItem } from '@mui/material'
import Icon from '../../../client/icons/Icon'
import { useNavigate } from 'react-router-dom'
import { testEmailRule, useJunoCommonFunctions } from '../../../helpers'
import JncButton from '../../../client/components/global/JncButton'

export default function JunoRegistrationBusinessDetails(props) {
    const { handleCreateAccount, form, setForm, loading, errorMsg } = props
    const navigate = useNavigate();
    const [error, setError] = useState([])
    const { getCountries } = useJunoCommonFunctions()
    const [countries, setCountries] = useState([])

    const onBack = () => {
        navigate('/register');
    };

    const validateField = (name, value) => {
        switch (name) {
            case 'businessEmail':
                return value.trim() === '' || !testEmailRule(value)
                    ? '* Enter a valid email address'
                    : '';
            case 'fullLegalEntityName':
                return value.trim() === '' ? '* Full Legal Entity Name is required' : '';
            case 'businessRepresentativeName':
                return value.trim() === '' ? '* Business Representative Name is required' : '';
            case 'businessWebsite':
                if (value.trim() === '') return '* Business Website is required'

                try {
                    const url = new URL(value.trim())
                    const validProtocols = ['http:', 'https:']
                    if (!validProtocols.includes(url.protocol)) {
                        return '* Website must start with http:// or https://'
                    }
                    if (!url.hostname.includes('.')) {
                        return '* Please enter a valid domain name'
                    }
                    return ''
                } catch {
                    return '* Please enter a valid website URL'
                }
            case 'countryOfIncorporation':
                return value.trim() === '' ? '* Country of incorporation is required' : '';
            case 'address':
                return value.trim() === '' ? '* Address line is required' : '';
            case 'city':
                return value.trim() === ''
                    ? '* City is required'
                    : !/^[A-Za-z\s]*$/.test(value)
                        ? '* City must contain only Latin letters and spaces'
                        : '';

            case 'state':
                return value.trim() === ''
                    ? '* State is required'
                    : !/^[A-Za-z\s]*$/.test(value)
                        ? '* State must contain only Latin letters and spaces'
                        : '';
            case 'postCode':
                return value.trim() === ''
                    ? '* ZIP / Postal Code is required'
                    : !/^[a-zA-Z0-9\s-]+$/.test(value)
                        ? '* ZIP / Postal Code must contain only letters, numbers, spaces, or hyphens'
                        : '';
            default:
                return '';
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
        setError((prevError) => ({
            ...prevError,
            [name]: validateField(name, value),
        }));
    };

    const handleSelectChange = (event) => {
        const { value } = event.target;
        setForm((prevForm) => ({
            ...prevForm,
            countryOfIncorporation: value,
        }));
        setError((prevError) => ({
            ...prevError,
            countryOfIncorporation: validateField('countryOfIncorporation', value),
        }));
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        setError((prevError) => ({
            ...prevError,
            [name]: validateField(name, value),
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = {};
        Object.keys(form).forEach((key) => {
            newErrors[key] = validateField(key, form[key]);
        });

        setError(newErrors);
        const hasErrors = Object.values(newErrors).some((err) => err !== '');
        if (!hasErrors) {
            handleCreateAccount()
        }
    };

    const fetchCountries = async () => {
        try {
            const countries = await getCountries();
            setCountries(countries?.allCountries)
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    useEffect(() => {
        fetchCountries()
    }, [])

    return (
        <div className="flex flex-col gap-8 bg-gray-100 w-full md:w-[500px] rounded-lg text-black h-full md:max-h-[726px] lg:max-h-[560px] overflow-auto">
            <div className="px-[14px] pb-[22px] border-b-[1px] gap-3 hidden md:flex py-8 sticky top-0 z-10 bg-gray-100 ">
                <span onClick={onBack} className="self-center cursor-pointer">
                    <Icon id="arrowLeft" />
                </span>
                <p className="text-[#18181B] font-semibold text-2xl ">
                    Business details
                </p>
            </div>
            <div
                className="flex flex-col justify-center w-full px-4"
            >
                <form onSubmit={handleSubmit}>
                    {errorMsg && <div className="alert alert-danger" role="alert">*{errorMsg}</div>}
                    <div className='jncAuth__field mb'>
                        <p className='jncAuth__field-label'>
                            Business Email
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1.2rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            type='email'
                            value={form.businessEmail}
                            placeholder='Enter business email'
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name='businessEmail'
                        />
                        {error.businessEmail && <span className='text-red-400'>{error.businessEmail}</span>}
                    </div>
                    <div className="jncAuth__field mb w-full">
                        <p className="jncAuth__field-label">
                            Full Legal Entity Name
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1.2rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.fullLegalEntityName}
                            placeholder='Enter legal entity name'
                            onChange={handleInputChange}
                            name='fullLegalEntityName'
                            onBlur={handleBlur}
                        />
                        {error.fullLegalEntityName && <span className='text-red-400'>{error.fullLegalEntityName}</span>}

                    </div>

                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            Business Representatives Name
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1.2rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.businessRepresentativeName}
                            placeholder='Enter business representatives name'
                            onChange={handleInputChange}
                            name='businessRepresentativeName'
                            onBlur={handleBlur}
                        />
                        {error.businessRepresentativeName && <span className='text-red-400'>{error.businessRepresentativeName}</span>}
                    </div>
                    <div className='jncAuth__field mb'>
                        <p className='jncAuth__field-label'>
                            Business Website
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1.2rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.businessWebsite}
                            placeholder='Business website'
                            onChange={handleInputChange}
                            name='businessWebsite'
                            onBlur={handleBlur}
                        />
                        {error.businessWebsite && <span className='text-red-400'>{error.businessWebsite}</span>}
                    </div>
                    <div className="jncAuth__field mb-[30px]">
                        <p className="jncAuth__field-label">
                            Country of incorporation
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1.2rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>

                        {/* Select Field */}
                        <FormControl fullWidth>
                            <Select
                                value={form.countryOfIncorporation}
                                onChange={handleSelectChange}
                                displayEmpty
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            width: '250px',
                                        },
                                    },
                                }}
                                sx={{
                                    border: '1px solid #E5E7EB',
                                    borderRadius: '8px',
                                    padding: 0,
                                    '.MuiSelect-outlined': {
                                        padding: '10px',
                                    },
                                    backgroundColor: 'white',
                                }}
                            >
                                <MenuItem value="">
                                    Select country of incorporation
                                </MenuItem>
                                {countries.map((country) => (
                                    <MenuItem key={country.name} value={country.name}>
                                        {country.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {error.countryOfIncorporation && <span className='text-red-400'>{error.countryOfIncorporation}</span>}
                    </div>
                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            Business Address Line
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.address}
                            type='text'
                            placeholder="Enter address line"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name="address"
                        />
                        {error.address && <span className='text-red-400'>{error.address}</span>}
                    </div>

                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            City
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.city}
                            type='city'
                            placeholder="Enter city"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name="city"
                        />
                        {error.city && <span className='text-red-400'>{error.city}</span>}
                    </div>

                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            State / Province / Region
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.state}
                            type='text'
                            placeholder="Enter state / province / region"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name="state"
                        />
                        {error.state && <span className='text-red-400'>{error.state}</span>}
                    </div>

                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            ZIP / Postal Code
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.postCode}
                            type='text'
                            placeholder="Enter zip / postal code"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name="postCode"
                        />
                        {error.postCode && <span className='text-red-400'>{error.postCode}</span>}
                    </div>
                    <JncButton
                        text="Continue"
                        type={"submit"}
                        disabled={error.length > 0}
                        className="w-full mb-7"
                        loading={loading}
                    />
                </form>
            </div>
        </div>
    )
}
