export async function apiService(url, request, successCall, errorCall) {
    try {
        const email = localStorage.getItem("user_name") || "";
        const domainNameUrl = localStorage.getItem("domainNameUrl") || "";

        const headers = {
            'Content-Type': 'application/json',
            'api-key': "CorrectSecretKey",
        };

        if (email) {
            request.email = email;
        }

        if (domainNameUrl && window.location.hostname !== "localhost" && domainNameUrl !== "null") {
            url = (domainNameUrl.includes("https://") ? domainNameUrl : "https://" + domainNameUrl) + url;
        }

        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(request),
            credentials: "include"
        });

        if (!response.ok) {
            const errorMessage = response.statusText || 'Failed to fetch';

            if (response.status === 403) {
                alert('Forbidden. You do not have permission to access this resource.');
            } else if ([502, 503, 504].includes(response.status)) {
                // Redirect to the maintenance page for error related statuses
                window.location.href = '/maintenance';
                return;
            }
            
            const errorResponse = await response.json();
            const errorMsg = errorResponse.msg || errorMessage;
            errorCall(errorMsg, response?.status);
            throw new Error(errorMsg);
        }

        const contentType = response.headers.get('content-type');
        const data = contentType && contentType.includes('application/json') ? await response.json() : await response.text();
        successCall(data);
    } catch (error) {
        if (error instanceof TypeError && error.message === 'Failed to fetch') {
            console.log("Payment server down! Please try again later.");
        } else if (error instanceof SyntaxError) {
            console.log("Invalid JSON response from server.");
        } else if (error instanceof Error) {
            console.error('Error occurred while fetching data:', error.message);
        }
    }
}
