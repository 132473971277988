import React, { useState, useEffect } from 'react'
import { Typography, FormControl, Select, MenuItem } from '@mui/material'
import JunoRegistrationDayPicker from './JunoRegistrationDayPicker'
import Icon from '../../../client/icons/Icon'
import { useNavigate } from 'react-router-dom'
import JncButton from '../../../client/components/global/JncButton'
import { isValidPhoneNo, testEmailRule, useJunoCommonFunctions } from '../../../helpers'

export default function JunoRegistrationPersonalDetails(props) {
    const [error, setError] = useState([])
    const { form, setForm, handleCreateAccount, loading, errorMsg } = props
    const navigate = useNavigate()
    const { getCountries } = useJunoCommonFunctions()
    const [countries, setCountries] = useState([])
    const validateField = (name, value) => {
        switch (name) {
            case 'clientEmail':
                return value.trim() === '' || !testEmailRule(value)
                    ? '* Enter a valid email address'
                    : '';
            case 'fullName':
                return value.trim() === '' ? '* Full Name is required' : '';
            case 'phone': {
                if (value.trim() === '') return '* Phone number is required'
                if (!isValidPhoneNo(value)) return '* Please enter a valid phone number'
            }
            case 'dateOfBirth':
                return value.trim() === '' ? '* Date of birth is required' : '';
            case 'country':
                return value.trim() === '' ? '* Country is required' : '';
            case 'address':
                return value.trim() === '' ? '* Address line is required' : '';
            case 'city':
                return value.trim() === ''
                    ? '* City is required'
                    : !/^[A-Za-z\s]*$/.test(value)
                        ? '* City must contain only Latin letters and spaces'
                        : '';

            case 'state':
                return value.trim() === ''
                    ? '* State is required'
                    : !/^[A-Za-z\s]*$/.test(value)
                        ? '* State must contain only Latin letters and spaces'
                        : '';
            case 'postCode':
                return value.trim() === ''
                    ? '* ZIP / Postal Code is required'
                    : !/^[a-zA-Z0-9\s-]+$/.test(value)
                        ? '* ZIP / Postal Code must contain only letters, numbers, spaces, or hyphens'
                        : '';
            default:
                return '';
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target
        setForm((prevForm) => ({
            ...prevForm,
            ['country']: value,
        }))
        setError((prevError) => ({
            ...prevError,
            country: validateField('country', value),
        }));
    }

    const handleBlur = (event) => {
        const { name, value } = event.target;
        setError((prevError) => ({
            ...prevError,
            [name]: validateField(name, value),
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target
        if (name === "phone" && /\D/.test(value)) {
            return;
        }
        const sanitizedValue = name === "phone" ? value.replace(/\D/g, '') : value;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: sanitizedValue,
        }))
        setError((prevError) => ({
            ...prevError,
            [name]: validateField(name, sanitizedValue),
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = {};
        Object.keys(form).forEach((key) => {
            newErrors[key] = validateField(key, form[key] || ''); // validate each field
        });

        setError(newErrors);
        const hasErrors = Object.values(newErrors).some((err) => err !== '');
        if (!hasErrors) {
            handleCreateAccount();
        }
    };

    const fetchCountries = async () => {
        try {
            const countries = await getCountries();
            setCountries(countries?.allCountries)
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    useEffect(() => {
        fetchCountries()
    }, [])

    return (
        <div className="flex flex-col gap-8 bg-gray-100 w-full md:w-[500px]  rounded-lg text-black h-full md:max-h-[726px] lg:max-h-[560px] overflow-auto">
            <div className="px-[14px] pb-[22px] border-b-[1px] gap-3 hidden md:flex py-8 sticky top-0 z-10 bg-gray-100 ">
                <span onClick={() => navigate('/register')} className="self-center cursor-pointer">
                    <Icon id="arrowLeft" />
                </span>
                <p className="text-[#18181B] font-semibold text-2xl ">
                    Your personal details
                </p>
            </div>
            <div className="flex flex-col justify-center w-full px-4">
                <form onSubmit={handleSubmit} >
                    {errorMsg && <div className="alert alert-danger" role="alert">*{errorMsg}</div>}
                    <div className="jncAuth__field mb w-full">
                        <p className="jncAuth__field-label">
                            Full name
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.fullName}
                            placeholder="Enter your full name"
                            onChange={handleInputChange}
                            name="fullName"
                            onBlur={handleBlur}
                        />
                        {error.fullName && <span className='text-red-400'>{error.fullName}</span>}
                    </div>
                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            Email
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            type="email"
                            value={form.clientEmail}
                            placeholder="Enter your email"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name="clientEmail"
                        />
                        {error.clientEmail && <span className='text-red-400'>{error.clientEmail}</span>}
                    </div>
                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            Phone
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.phone}
                            type='tel'
                            placeholder="Enter your phone"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name="phone"
                        />
                        {error.phone && <span className='text-red-400'>{error.phone}</span>}
                    </div>
                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            Date of Birth
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <JunoRegistrationDayPicker
                            form={form}
                            setForm={setForm}
                            setError={setError}
                            validateField={validateField}
                        />
                        {error.dateOfBirth && <span className='text-red-400'>{error.dateOfBirth}</span>}
                    </div>
                    <div className="jncAuth__field mb-[30px]">
                        <p className="jncAuth__field-label">
                            Country of residence
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>

                        {/* Select Field */}
                        <FormControl fullWidth>
                            <FormControl fullWidth>
                                <Select
                                    value={form.country}
                                    onChange={handleChange}
                                    displayEmpty
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                                width: '250px',
                                            },
                                        },
                                    }}
                                    sx={{
                                        border: '1px solid #E5E7EB',
                                        borderRadius: '8px',
                                        padding: 0,
                                        '.MuiSelect-outlined': {
                                            padding: '10px',
                                        },
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <MenuItem value="">
                                        Select your country
                                    </MenuItem>
                                    {countries.map((country) => (
                                        <MenuItem key={country.name} value={country.name}>
                                            {country.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </FormControl>
                        {error.country && <span className='text-red-400'>{error.country}</span>}
                    </div>

                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            Personal Address Line
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.address}
                            type='text'
                            placeholder="Enter address line"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name="address"
                        />
                        {error.address && <span className='text-red-400'>{error.address}</span>}
                    </div>

                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            City
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.city}
                            type='city'
                            placeholder="Enter city"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name="city"
                        />
                        {error.city && <span className='text-red-400'>{error.city}</span>}
                    </div>

                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            State / Province / Region
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.state}
                            type='text'
                            placeholder="Enter state / province / region"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name="state"
                        />
                        {error.state && <span className='text-red-400'>{error.state}</span>}
                    </div>

                    <div className="jncAuth__field mb">
                        <p className="jncAuth__field-label">
                            ZIP / Postal Code
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <input
                            value={form.postCode}
                            type='text'
                            placeholder="Enter zip / postal code"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            name="postCode"
                        />
                        {error.postCode && <span className='text-red-400'>{error.postCode}</span>}
                    </div>

                    <JncButton
                        text="Continue"
                        type={"submit"}
                        className="w-full mb-7"
                        disabled={error.length > 0}
                        loading={loading}
                    />
                </form>
            </div>
        </div>
    )
}
