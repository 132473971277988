import React, { useState, useEffect } from 'react'
import RegistrationLayout from '../../client/layouts/RegistrationLayout'
import { setKycStatus } from '../../helpers'
import JunoVerifyEmail from '../components/register/JunoVerifyEmail'
import JunoCreatePassword from '../components/register/JunoCreatePassword'
import JunoRegistrationPersonalDetails from '../components/register/JunoRegistrationPersonalDetails'
import Stepper from '../components/register/Stepper'
import { useLocation, useNavigate } from 'react-router-dom'
import { KYCSTATUSES } from '../../client/constant'
import { apiService } from '../../../common/apiCallService'
import { format, parse } from 'date-fns'
import { useSelector } from 'react-redux'
import JunoAccountPurpose from '../components/register/JunoAccountPurpose'
import TransactionActivity from '../components/register/TransactionActivity'

const stepMap = {
    onboarding: 1,
    emailVerification: 2,
    passwordCreation: 3,
    accountPurpose: 4,
    transactionActivity: 5,
}
export default function PersonalRegistrationPage() {
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const apiUrl = useSelector((state) => state.config.api_url)
    const [step, setStep] = useState(1)
    const [form, setForm] = useState({
        fullName: '',
        clientEmail: '',
        phone: '',
        dateOfBirth: '',
        country: '',
        address:'',
        city:'',
        state:'',
        postCode:''
 
    })
    const [registrationData, setRegistrationData] = useState(null)
    const [errorMsg, setErrorMsg] = useState()
    const [headerContent, setHeaderContent] = useState({
        title: '',
        isBack: false,
        isSave: false,
    })
    const navigateState = location.state

    useEffect(() => {
        navigateState && setStep(stepMap[navigateState?.step])
    }, [navigateState])

    const handleCreateAccount = () => {
        setLoading(true)
        const parsedDate = parse(form.dateOfBirth, 'dd/MM/yyyy', new Date())

        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
                '/restapi/register',
            {
                accountType: 'Personal',
                dob: form.dateOfBirth && format(parsedDate, 'yyyy/MM/dd'),
                ...form,
            },
            (data) => {
                setLoading(false)
                const next = data?.userRegistrationState
                    ?.emailVerification
                    ? 3
                    : 2
                setStep(next)
            },
            (err) => {
                setLoading(false)
                console.log(err)
                setErrorMsg(err)
            }
        )
    }
    useEffect(() => {
        switch (step) {
            case 1:
                setHeaderContent({
                    title: 'Personal details',
                    isBack: true,
                })
                break
            case 2:
                setHeaderContent({
                    title: 'Verify',
                    isBack: true,
                })
                break
            case 3:
                setHeaderContent({
                    title: 'Create a Password',
                    isBack: true,
                })
                break
            case 4:
                setHeaderContent({
                    title: 'Account Purpose',
                    isBack: true,
                    isSave: true,
                })
                break
            case 5:
                setHeaderContent({
                    title: 'Transaction Activity',
                    isBack: true,
                    isSave: true,
                })
                break
        }
    }, [step])

    const handleBack = () => {
        if (step === 3) {
            registrationData?.userRegistrationState?.emailVerification
                ? setStep(1)
                : setStep(2)
        } else {
            step === 1 ? navigate('/register') : setStep(step - 1)
        }
    }

    const steps = ['Details', 'Verify', 'Password', 'Purpose', 'Transactions']
    const handleSaveClick = async () => {
        navigate('/client')
    }

    return (
        <RegistrationLayout
            stepper={<Stepper steps={steps} currentStep={step} />}
            headerContent={headerContent}
            handleBack={handleBack}
        >
            {step === 1 && (
                <JunoRegistrationPersonalDetails
                    form={form}
                    setForm={setForm}
                    loading={loading}
                    handleCreateAccount={handleCreateAccount}
                    errorMsg={errorMsg}
                />
            )}
            {step === 2 && (
                <JunoVerifyEmail
                    title="Verify Email"
                    onClick={() => {
                        setStep(3)
                    }}
                    onBack={() => setStep(1)}
                    email={form.clientEmail}
                    setRegistrationData={setRegistrationData}
                />
            )}

            {step === 3 && (
                <JunoCreatePassword
                    form={form}
                    onBack={() =>
                        registrationData?.userRegistrationState
                            ?.emailVerification
                            ? setStep(1)
                            : setStep(2)
                    }
                    setRegistrationData={setRegistrationData}
                    onClick={() => {
                        setStep(4)
                    }}
                />
            )}

            {step === 4 && (
                <JunoAccountPurpose
                    onBack={() => setStep(3)}
                    onClick={() => {                        
                        setStep(5)
                    }}
                    handleSaveClick={()=>{
                        navigate('/client')
                    }}
                />
            )}

            {step === 5 && (
                <TransactionActivity
                    onBack={() => setStep(4)}
                    onClick={() => {
                        setKycStatus(KYCSTATUSES['not-started'])
                        navigate('/client')
                    }}
                    handleSaveClick={handleSaveClick}
                />
            )}
        </RegistrationLayout>
    )
}
