import React from 'react'
import Icon from '../../icons/Icon'
import { useSelector } from 'react-redux'
import { toFixedTrunc, useJunoCommonFunctions } from '../../../helpers'
import { getCurrencySymbol } from '../../../helpers'

export default function BalanceCard({ data, setActiveModal, setActiveData }) {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const kycStatus = localStorage.getItem("kycStatus")

    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false
    const { validateFreezedCurrencies, handleTransactionModalLogic } = useJunoCommonFunctions();
    const openModal = async (modalName) => {
        handleTransactionModalLogic(kycStatus, () => validateFreezedCurrencies(modalName, () => {
            setActiveData(data);
            setActiveModal(modalName);
        }))
    };

    const balanceAmount = toFixedTrunc(data.balanceAmount, 2)
        .toString()
        .split('.')

    return (
        <div className="jncBalanceCard jncCard">
            <div className="jncBalanceCard__curr">
                <div>
                    <p className="min-992 jncBalanceCard__curr-value">
                        <span
                            data-e2e-currency={`${getCurrencySymbol(data.currencyShortName)}`}
                            data-e2e={`${balanceAmount[0]}`}
                            data-e2e-amountdecimal={`${balanceAmount[1]}`}
                        >
                            {getCurrencySymbol(data.currencyShortName)}&nbsp;
                        </span>
                        {balanceAmount[0]} <sup>.{balanceAmount[1]}</sup>
                    </p>
                    <p className="max-991 jncBalanceCard__curr-value">
                        {toFixedTrunc(data.balanceAmount, 2)}{' '}
                        {data.currencyShortName}
                    </p>
                    <p className="jncBalanceCard__curr-percent" data-e2e={toFixedTrunc(data.holdingPercentage, 2)} data-e2e-percentage={data.currencyShortName}>
                        {toFixedTrunc(data.holdingPercentage, 2)}%
                    </p>
                </div>
                <div className="jncBalanceCard__curr-img">
                    <img src={`${imageBaseUrl}${data.currencyIcon}`} />
                    <p className="max-991">{data.currencyFullName}</p>
                </div>
            </div>
            <div className="jncBalanceCard__actions">
                <button
                    data-e2e={`${data.currencyFullName}-deposit`}
                    onClick={() => openModal('deposit')}
                    className={`${readOnly && 'jnc-btn-disable'} jncActionBtn jncHint jncBalanceCard__actions-item`}
                    disabled={readOnly}
                >
                    <span>Deposit</span>
                    <Icon id="deposit" />
                </button>
                <button
                    data-e2e={`${data.currencyFullName}-withdraw`}
                    onClick={() => openModal('withdraw')}
                    className={`${readOnly && 'jnc-btn-disable'} jncActionBtn jncHint jncBalanceCard__actions-item`}
                    disabled={readOnly}
                >
                    <span>Withdrawal</span>
                    <Icon id="withdrawal" />
                </button>
                <button
                    data-e2e={`${data.currencyFullName}-third-party-payment`}
                    onClick={() => openModal('paymentOut')}
                    className={`${readOnly && 'jnc-btn-disable'} jncActionBtn jncHint jncBalanceCard__actions-item`}
                    disabled={readOnly}
                >
                    <span>Third-party payment</span>
                    <Icon id="paymentOut" />
                </button>
                <button
                    data-e2e={`${data.currencyFullName}-transfer`}
                    onClick={() => openModal('transfer')}
                    className={`${readOnly && 'jnc-btn-disable'} jncActionBtn  jncActionBtn-transfer  jncHint jncBalanceCard__actions-item`}
                    disabled={readOnly}
                >
                    <span>Transfer</span>
                    <Icon id="transfer" />
                </button>
                <button
                    data-e2e={`${data.currencyFullName}-fx`}
                    onClick={() => openModal('fx')}
                    className={`${readOnly && 'jnc-btn-disable'} jncActionBtn jncHint jncBalanceCard__actions-item`}
                    disabled={readOnly}
                >
                    <span>FX</span>
                    <Icon id="fx" />
                </button>
            </div>
        </div>
    )
}