import React, { useState, useEffect, useRef } from 'react'
import { setToken, setClientData } from '../../../../Redux/actions'
import { clearStorageData } from '../../../../common/getIP'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../icons/Icon'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import {
    getShortName,
    setKycStatus,
    useJunoCommonFunctions,
    useOutsideClick,
} from '../../../helpers'
import { Typeahead } from 'react-bootstrap-typeahead'
import { displayCurrencyPage } from '../../../../Redux/actions/junoAction'
import JunoVerifyBusinessModal from '../../../JunoMoney/components/register/JunoVerifyBusinessModal'
import { Dialog, DialogContent } from '@mui/material'
import { apiService } from '../../../../common/apiCallService'
import SumsubVerification from '../modals/SumsubVerification'
import East from '@mui/icons-material/East'
import { setShowVerificationModal } from '../../../../Redux/actions/commonAction'
import VerificationIssue from '../modals/VerificationIssue'
import { KYCSTATUSES } from '../../constant'
import JunoClientActionModal from '../table/JunoClientActionModal'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import JncButton from '../global/JncButton'
import { set } from 'lodash'

function JncHeader(props) {
    const { useWindowWidth, getGlobalSettings } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()
    const clientData = useSelector((state) => state.juno.clientData)
    const kycStatus = localStorage.getItem('kycStatus')
    const showVerificationModal = useSelector(
        (state) => state.common.showVerificationModal
    )
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const apiUrl = useSelector((state) => state.config.api_url)
    const { name, accountNumber } = clientData ? clientData : '-'
    const { toggleSidebar, setSelectedLogo, selectedLogo } = props
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const intervalRef = useRef()
    const [isProfileMenuActive, setProfileMenuState] = useState(false)
    const refProfileBlock = useRef()
    const [showHeader, setShowHeader] = useState(false)
    const headerLogo = sessionStorage.getItem('logo')
    const cryptoHeaderLogo = sessionStorage.getItem('cryptoLogo')
    const logoWidth = sessionStorage.getItem('logoWidth')
    const redirectURL = sessionStorage.getItem('redirectURL')
    const [isOpen, setIsOpen] = useState(false)
    const [logoOptions, setLogoOptions] = useState([])
    const storedSettings = localStorage.getItem('tabSettings')
    const [isSumsubEnabled, setIsSumsubEnabled] = useState(false)

    const [start, setStart] = useState(false)
    const [verificationIssueModal, setVerficationIssueModal] = useState(false)
    useEffect(() => {
        handleLogoOptions()
    }, [headerLogo, cryptoHeaderLogo, storedSettings])

    const handleLogoOptions = () => {
        const logos = [
            {
                src: headerLogo,
                alt: 'fiat',
            },
            {
                src: cryptoHeaderLogo,
                alt: 'crypto',
            },
        ]
        const parsedSettings = storedSettings ? JSON.parse(storedSettings) : {}

        const filteredLogos = logos.filter((logo) => {
            if (
                parsedSettings &&
                parsedSettings[`${logo.alt}Tab`] === 'Enable'
            ) {
                return true
            }
            return false
        })
        setLogoOptions(filteredLogos)
        const activeCurrencyPage =
            localStorage.getItem('activeCurrencyPage') || filteredLogos[0]?.alt
        const activeLogo = filteredLogos.find(
            (e) => e.alt === activeCurrencyPage
        )
        setSelectedLogo(activeLogo ? [activeLogo] : [filteredLogos[0]])
    }

    const handleLogo = (val) => {
        setSelectedLogo(val)
        localStorage.setItem('activeCurrencyPage', val[0]?.alt)
        dispatch(displayCurrencyPage(val[0]?.alt))
    }

    useOutsideClick(refProfileBlock, () => setProfileMenuState(false))
    const toggleProfileMenu = () => setProfileMenuState((prev) => !prev)

    function logout() {
        dispatch(setToken(null))
        dispatch(setClientData(null))
        clearStorageData()
        if (redirectURL) {
            window.open(redirectURL, '_self')
        } else {
            navigate('/')
            window.location.reload()
        }
    }

    function setFavicon() {
        let link = document.querySelector("link[rel~='icon']")
        if (!link) {
            link = document.createElement('link')
            link.rel = 'icon'
            document.getElementsByTagName('head')[0].appendChild(link)
        }
        const api_url = localStorage.getItem('api_url')
        link.href = `${api_url}/images/junoclient/junomoney-logo-icon.svg`
    }

    function checkForInactivity() {
        const expireTime = localStorage.getItem('expireTime')
        if (expireTime < Date.now()) {
            console.log('User has been logged out due to inactivity.')
            logout()
            clearInterval(intervalRef)
        } else {
            console.log('user is active')
        }
    }
    function updateExpireTime() {
        const expireTime = Date.now() + 15 * 60 * 1000
        localStorage.setItem('expireTime', expireTime)
    }
    const getSumsubSettings = async () => {
        getGlobalSettings('sumsub', (data) => {
            const value = data[0]?.value || false
            setIsSumsubEnabled(value)
        })
    }

    useEffect(() => {
        setFavicon()
        checkForInactivity()
        getSumsubSettings()
        let interval = setInterval(() => {
            checkForInactivity()
        }, 20000)
        intervalRef.current = interval
        window.addEventListener('click', updateExpireTime)
        window.addEventListener('keypress', updateExpireTime)
        window.addEventListener('scroll', updateExpireTime)
        window.addEventListener('mousemove', updateExpireTime)

        return () => {
            window.removeEventListener('click', updateExpireTime)
            window.removeEventListener('keypress', updateExpireTime)
            window.removeEventListener('scroll', updateExpireTime)
            window.removeEventListener('mousemove', updateExpireTime)
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        setShowHeader(location.pathname === '/client')
    }, [location.pathname])

    const getSumsubKycStatus = async (email) => {
        const endpoint = `${window.location.hostname === 'localhost' ? apiUrl : ''
            }/restapi/kyc-getKycStatus`
        apiService(
            endpoint,
            { clientEmail: email },
            async (data) => {
                if (data) {
                    if (data.reviewStatus === 'completed') {
                        if (data.reviewAnswer === 'GREEN') {
                            setKycStatus(KYCSTATUSES.completed)
                        } else {
                            if (kycStatus === KYCSTATUSES.retry) {
                                setKycStatus(KYCSTATUSES.retry)
                            } else {
                                setKycStatus(KYCSTATUSES.failed)
                            }
                        }
                    } else if (data.reviewStatus === 'init') {
                        setKycStatus(KYCSTATUSES['not-started'])
                    } else {
                        setKycStatus(KYCSTATUSES.pending)
                    }
                } else {
                    setKycStatus(KYCSTATUSES['not-started'])
                }
            },
            (error) => {
                console.error(error)
            }
        )
    }

    useEffect(() => {
        localStorage.setItem('isSumsubEnabled', isSumsubEnabled)
        if (kycStatus && kycStatus !== KYCSTATUSES.completed && isSumsubEnabled) {
            getSumsubKycStatus(localStorage.getItem('user_name'))
        }
    }, [isSumsubEnabled])

    const kycMessages = {
        'not-started': {
            text: 'To execute any transactions, please validate your identity.',
            textColor: 'text-[#B54708]',
            bgColor: 'bg-[#FEF0C7]',
            iconVisible: true,
        },
        pending: {
            text: 'Your identity is under verification. This may take up till 1 business day',
            textColor: 'text-[#004EEB]',
            bgColor: 'bg-[#D1E9FF]',
            iconVisible: false,
        },
        retry: {
            text: 'There was an identity verification error. Press here to fix',
            textColor: 'text-[#C11574]',
            bgColor: 'bg-[#FBE8FF]',
            iconVisible: true,
        },
        failed: {
            text: 'Account Verification issue',
            textColor: 'text-[#C11574]',
            bgColor: 'bg-[#FBE8FF]',
            iconVisible: true,
        },
    }

    const handleModalClick = (status) => {
        const modalStatus = status === 'retry' ? 'start' : status
        dispatch(setShowVerificationModal(modalStatus))
    }

    const [showFinalVerificationModal, setShowFinalVerificationModal] =
        useState(false)

    useEffect(() => {
        (async () => {
            if (clientData) {
                const {
                    accountType,
                    userRegistrationState: {
                        accountPurpose,
                        transactionActivity,
                        uploadDocuments,
                        finalVerification,
                    } = {},
                } = clientData

                const isBusiness = accountType === 'Business' && uploadDocuments
                const isPersonal =
                    accountType === 'Personal' &&
                    kycStatus === KYCSTATUSES.completed
                const isEligibleForFinalVerification =
                    accountPurpose && transactionActivity && !finalVerification
                if (accountType === 'Personal' && accountPurpose && transactionActivity) dispatch(setShowVerificationModal(kycStatus))

                if (
                    (isBusiness || isPersonal) &&
                    isEligibleForFinalVerification
                ) {
                    setShowFinalVerificationModal(true)
                } else setShowFinalVerificationModal(false)
            }
        })()
    }, [clientData, kycStatus])

    return (
        <>
            <header
                className={`jncHeader flex-col mb-2 ${showHeader ? 'show-header' : ''
                    }`}
            >
                <div className="jncHeader__container py-2.5 h-[72px]">
                    <div className="jncHeader__row">
                        <div className="jncHeader__sidebar">
                            <button
                                data-e2e="main-menu"
                                className="jncHeader__sidebar-btn"
                                onClick={toggleSidebar}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                >
                                    <path
                                        d="M5 30V26.6667H35V30H5ZM5 21.6667V18.3333H35V21.6667H5ZM5 13.3333V10H35V13.3333H5Z"
                                        fill="black"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="jncHeader__center">
                            <div className="jncTable-inputs-group">
                                {logoOptions.length > 1 ? (
                                    <div
                                        className={`typeahead-custom ${selectedLogo.length > 0
                                            ? 'has-value'
                                            : ''
                                            }`}
                                    >
                                        {selectedLogo.length > 0 && (
                                            <div>
                                                <img
                                                    className="typeahead-custom-img crypto-header-logo"
                                                    src={
                                                        selectedLogo &&
                                                        selectedLogo[0]?.src
                                                    }
                                                    alt={selectedLogo[0]?.alt}
                                                    onLoad={(e) => {
                                                        const inputElement =
                                                            document.getElementById(
                                                                'logo-typeahead-input'
                                                            )
                                                        if (inputElement) {
                                                            inputElement.style.width = `${e.target.width +
                                                                60
                                                                }px`
                                                        }
                                                    }}
                                                    height="auto"
                                                />
                                                <div
                                                    className={`header-logo-dropdown`}
                                                >
                                                    {!isOpen ? (
                                                        <Icon id="upArrow" />
                                                    ) : (
                                                        <Icon id="downArrow" />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <Typeahead
                                            id="logo-typeahead"
                                            labelKey={(option) => ``}
                                            onChange={handleLogo}
                                            options={logoOptions}
                                            selected={selectedLogo}
                                            inputProps={{
                                                readOnly: true,
                                                id: 'logo-typeahead-input',
                                            }}
                                            filterBy={() => true}
                                            onMenuToggle={(e) => setIsOpen(e)}
                                            renderMenuItemChildren={(
                                                option
                                            ) => (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <img
                                                        src={option.src}
                                                        alt={option.alt}
                                                        width="auto"
                                                        style={{
                                                            maxWidth: '140px',
                                                        }}
                                                        height="auto"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                ) : (
                                    <NavLink
                                        className="jncHeader__logo"
                                        to={'/client'}
                                    >
                                        {logoOptions.length > 0 && (
                                            <img
                                                src={logoOptions[0].src}
                                                alt="logo"
                                                width="130"
                                                height="auto"
                                                data-e2e="logo"
                                            />
                                        )}
                                    </NavLink>
                                )}
                            </div>
                        </div>
                        <NavLink
                            to="/client/profile"
                            className="jncHeader__profile max-991"
                        >
                            <Icon id="headerProfile" />
                        </NavLink>
                        <div
                            ref={refProfileBlock}
                            className="jncHeader__profile min-992"
                        >
                            <button
                                className="jncHeader__profile-toggle"
                                onClick={toggleProfileMenu}
                            >
                                <div className="jncHeader__profile-info min-992">
                                    <p className="text-right">{name}</p>
                                    <p className="jncHeader__profile-number text-right">
                                        Account Number: {accountNumber}
                                    </p>
                                </div>
                                <div
                                    className="jncHeader__profile-icon"
                                    data-e2e="profile-icon"
                                >
                                    {getShortName(name)}
                                </div>
                            </button>
                            <div
                                className={`jncHeader__menu ${isProfileMenuActive ? 'active' : ''
                                    }`}
                            >
                                <div className="jncHeader__profile-info max-991">
                                    <p className="text-right">{name}</p>
                                    <p className="jncHeader__profile-number text-right">
                                        Account Number: {accountNumber}
                                    </p>
                                </div>
                                <NavLink
                                    data-e2e="home-option"
                                    to="/client"
                                    className="jncHeader__menu-link"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M4 11.4522V16.8002C4 17.9203 4 18.4807 4.21799 18.9086C4.40973 19.2849 4.71547 19.5906 5.0918 19.7823C5.5192 20.0001 6.07899 20.0001 7.19691 20.0001H16.8031C17.921 20.0001 18.48 20.0001 18.9074 19.7823C19.2837 19.5906 19.5905 19.2849 19.7822 18.9086C20 18.4811 20 17.9216 20 16.8037V11.4522C20 10.9179 19.9995 10.6506 19.9346 10.4019C19.877 10.1816 19.7825 9.97307 19.6546 9.78464C19.5102 9.57201 19.3096 9.39569 18.9074 9.04383L14.1074 4.84383C13.3608 4.19054 12.9875 3.86406 12.5674 3.73982C12.1972 3.63035 11.8026 3.63035 11.4324 3.73982C11.0126 3.86397 10.6398 4.19014 9.89436 4.84244L5.09277 9.04383C4.69064 9.39569 4.49004 9.57201 4.3457 9.78464C4.21779 9.97307 4.12255 10.1816 4.06497 10.4019C4 10.6506 4 10.9179 4 11.4522Z"
                                            stroke="#344054"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    Home
                                </NavLink>
                                <NavLink
                                    data-e2e="balances-option"
                                    to="/client/balances"
                                    className="jncHeader__menu-link"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="19"
                                        viewBox="0 0 20 19"
                                        fill="none"
                                    >
                                        <path
                                            d="M17 18H3C2.46957 18 1.96086 17.7893 1.58579 17.4142C1.21071 17.0391 1 16.5304 1 16V7C1 6.46957 1.21071 5.96086 1.58579 5.58579C1.96086 5.21071 2.46957 5 3 5H17C17.5304 5 18.0391 5.21071 18.4142 5.58579C18.7893 5.96086 19 6.46957 19 7V16C19 16.5304 18.7893 17.0391 18.4142 17.4142C18.0391 17.7893 17.5304 18 17 18Z"
                                            stroke="#344054"
                                            strokeWidth="1.5"
                                        />
                                        <path
                                            d="M14.5 12C14.3674 12 14.2402 11.9473 14.1464 11.8536C14.0527 11.7598 14 11.6326 14 11.5C14 11.3674 14.0527 11.2402 14.1464 11.1464C14.2402 11.0527 14.3674 11 14.5 11C14.6326 11 14.7598 11.0527 14.8536 11.1464C14.9473 11.2402 15 11.3674 15 11.5C15 11.6326 14.9473 11.7598 14.8536 11.8536C14.7598 11.9473 14.6326 12 14.5 12Z"
                                            fill="#344054"
                                            stroke="#344054"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M16 4.99996V3.60296C15.9999 3.29646 15.9294 2.99408 15.7939 2.71918C15.6583 2.44427 15.4614 2.20419 15.2184 2.01747C14.9753 1.83075 14.6926 1.7024 14.3921 1.64232C14.0915 1.58224 13.7812 1.59204 13.485 1.67096L2.485 4.60396C2.05905 4.71747 1.68254 4.96852 1.41399 5.31809C1.14544 5.66765 0.999902 6.09615 1 6.53696V6.99996"
                                            stroke="#344054"
                                            strokeWidth="1.5"
                                        />
                                    </svg>
                                    Balances
                                </NavLink>
                                <NavLink
                                    data-e2e="transactions-option"
                                    to="/client/transactions"
                                    className="jncHeader__menu-link"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M4.266 16.06C5.11205 17.7705 6.4842 19.1647 8.181 20.038C9.86564 20.903 11.7864 21.1951 13.652 20.87C15.5234 20.541 17.2379 19.6148 18.539 18.23C19.8521 16.8321 20.6882 15.0539 20.927 13.151C21.1696 11.2437 20.8043 9.30851 19.883 7.62098C18.9703 5.9463 17.544 4.60898 15.814 3.80598C14.0957 3.01014 12.1646 2.79667 10.314 3.19798C8.464 3.59898 6.948 4.51098 5.694 5.95298C5.543 6.11298 4.959 6.75898 4.474 7.73398M7.5 7.99998L3.891 8.71998L3 4.99998M12 8.99998V13L15 15"
                                            stroke="#344054"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    Transaction history
                                </NavLink>
                                <NavLink
                                    data-e2e="accounts-option"
                                    exact
                                    to="/client/accounts"
                                    className="jncHeader__menu-link"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            className="fill"
                                            d="M6.5 10H4.5V17H6.5V10ZM12.5 10H10.5V17H12.5V10ZM21 19H2V21H21V19ZM18.5 10H16.5V17H18.5V10ZM11.5 3.26L16.71 6H6.29L11.5 3.26ZM11.5 1L2 6V8H21V6L11.5 1Z"
                                            fill="#344054"
                                        />
                                    </svg>
                                    Accounts
                                </NavLink>
                                <NavLink
                                    data-e2e="profile-option"
                                    to="/client/profile"
                                    className="jncHeader__menu-link"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            className="fill"
                                            d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10C8 8.93913 8.42143 7.92172 9.17157 7.17157C9.92172 6.42143 10.9391 6 12 6C13.0609 6 14.0783 6.42143 14.8284 7.17157C15.5786 7.92172 16 8.93913 16 10Z"
                                            fill="#344054"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M11.592 21.992C6.2585 21.778 2 17.386 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C11.9543 22.0003 11.9087 22.0003 11.863 22C11.7725 22 11.682 21.997 11.592 21.992ZM5.583 18.31C5.50823 18.0953 5.48278 17.8665 5.50854 17.6406C5.5343 17.4147 5.6106 17.1975 5.73179 17.0051C5.85298 16.8128 6.01593 16.6502 6.20856 16.5294C6.40119 16.4086 6.61855 16.3328 6.8445 16.3075C10.7425 15.876 13.2815 15.915 17.1605 16.3165C17.3868 16.3401 17.6046 16.4149 17.7976 16.5354C17.9905 16.6559 18.1534 16.8189 18.2739 17.0119C18.3943 17.2048 18.4691 17.4227 18.4927 17.649C18.5162 17.8753 18.4877 18.1039 18.4095 18.3175C20.072 16.6354 21.003 14.365 21 12C21 7.0295 16.9705 3 12 3C7.0295 3 3 7.0295 3 12C3 14.458 3.9855 16.686 5.583 18.31Z"
                                            fill="#344054"
                                        />
                                    </svg>
                                    Profile
                                </NavLink>
                                <div className="jncHeader__menu-line"></div>
                                <button
                                    data-e2e="logout"
                                    className="jncHeader__menu-link"
                                    onClick={logout}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M15 3.00098C15.1313 3.00098 15.2614 3.02684 15.3827 3.0771C15.504 3.12735 15.6142 3.20101 15.7071 3.29387C15.8 3.38673 15.8736 3.49697 15.9239 3.61829C15.9741 3.73962 16 3.86965 16 4.00098C16 4.1323 15.9741 4.26233 15.9239 4.38366C15.8736 4.50499 15.8 4.61523 15.7071 4.70808C15.6142 4.80094 15.504 4.8746 15.3827 4.92486C15.2614 4.97511 15.1313 5.00098 15 5.00098H6V18.001C6 18.2662 6.10536 18.5205 6.29289 18.7081C6.48043 18.8956 6.73478 19.001 7 19.001H15C15.2652 19.001 15.5196 19.1063 15.7071 19.2939C15.8946 19.4814 16 19.7358 16 20.001C16 20.2662 15.8946 20.5205 15.7071 20.7081C15.5196 20.8956 15.2652 21.001 15 21.001H7C6.20435 21.001 5.44129 20.6849 4.87868 20.1223C4.31607 19.5597 4 18.7966 4 18.001V4.00098C4 3.73576 4.10536 3.48141 4.29289 3.29387C4.48043 3.10633 4.73478 3.00098 5 3.00098H15ZM16.707 8.29398C16.5672 8.15423 16.3891 8.05904 16.1953 8.02044C16.0014 7.98184 15.8005 8.00156 15.6178 8.07711C15.4351 8.15265 15.279 8.28064 15.169 8.44489C15.0591 8.60915 15.0002 8.80231 15 8.99998V11H9C8.73478 11 8.48043 11.1053 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12C8 12.2652 8.10536 12.5195 8.29289 12.7071C8.48043 12.8946 8.73478 13 9 13H15V15C15 15.1977 15.0587 15.391 15.1686 15.5554C15.2785 15.7198 15.4346 15.848 15.6173 15.9237C15.8 15.9993 16.0011 16.0191 16.195 15.9806C16.389 15.942 16.5671 15.8468 16.707 15.707L19.707 12.707C19.8945 12.5194 19.9998 12.2651 19.9998 12C19.9998 11.7348 19.8945 11.4805 19.707 11.293L16.707 8.29398Z"
                                            fill="#344054"
                                        />
                                    </svg>
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {isSumsubEnabled &&
                        clientData?.accountType === 'Personal' &&
                        kycStatus &&
                        clientData?.userRegistrationState?.accountPurpose &&
                        clientData?.userRegistrationState
                            ?.transactionActivity &&
                        kycMessages[kycStatus] && (
                            <div
                                style={{}}
                                onClick={() => handleModalClick(kycStatus)}
                                className={`cursor-pointer lg:ml-[18px] text-xs lg:text-sm font-semibold ${kycMessages[kycStatus].textColor} ${kycMessages[kycStatus].bgColor} rounded-lg flex justify-center gap-2.5 h-[30px] lg:h-11 items-center`}
                            >
                                {kycMessages[kycStatus].text}
                                {kycMessages[kycStatus].iconVisible && (
                                    <East className="!hidden lg:!block" />
                                )}
                            </div>
                        )}
                    {showFinalVerificationModal ? (
                        <div
                            style={{}}
                            className={`cursor-pointer lg:ml-[18px] text-xs lg:text-sm font-semibold text-[#364152] bg-[#E3E8EF] rounded-lg flex justify-center gap-2.5 h-[30px] lg:h-11 items-center`}
                        >
                            <AccessTimeIcon
                                className="!hidden lg:!block"
                                fontSize="small"
                            />{' '}
                            Your{' '}
                            {clientData?.accountType === 'Business'
                                ? 'Business'
                                : 'Personal'}{' '}
                            details are under verification. This might take 24h
                        </div>
                    ) : null}
                </div>
            </header>

            <JunoVerifyBusinessModal
                open={showVerificationModal === 'verificationPending'}
                onClose={() => dispatch(setShowVerificationModal(''))}
            />

            {/* Verification Error Modal */}
            <VerificationIssue
                open={showVerificationModal === KYCSTATUSES.failed}
                onClose={() => dispatch(setShowVerificationModal(''))}
            />

            {/* Under Verification Modal */}
            <JunoClientActionModal
                dialogClassName="jn-acc-verification-message-sent"
                onClose={() => dispatch(setShowVerificationModal(''))}
                states={{
                    start: true,
                }}
                open={showVerificationModal === KYCSTATUSES.pending}
                dialogContent={
                    <div className="flex justify-center items-center flex-col validationModal">
                        <div className="rounded-full bg-[#FAFAFA] p-3">
                            <div className=" bg-[#F4F4F5] rounded-full p-3 ">
                                <div className="rounded-full flex items-center justify-center">
                                    <AccessTimeIcon className="!w-10 !h-10 text-[#51525C] m-0" />
                                </div>
                            </div>
                        </div>
                        <p className="validationModal font-semibold mt-8">
                            Your identity is under verification.
                        </p>
                        <p className="validationModal-subtitle">
                            In order to execute any transaction we require you
                            to validate your identity. Our trusted partner will
                            handle the next step.
                        </p>
                    </div>
                }
                dialogActions={
                    <div className="jncModal__resp w-full">
                        <JncButton
                            text={
                                <p className="!text-white font-medium">
                                    I understand
                                </p>
                            }
                            onClickCall={() =>
                                dispatch(setShowVerificationModal(''))
                            }
                            className={'w-full !bg-[#18181B]'}
                        />
                    </div>
                }
            />
            {/* First Modal to begin verification */}
            <Dialog
                fullScreen={windowWidth < 768}
                className="jncModal "
                onClose={() => dispatch(setShowVerificationModal(''))}
                open={showVerificationModal === KYCSTATUSES['not-started']}
            >
                <DialogContent dividers>
                    <div className="rounded-[12px] h-full border border-[#F2F4F7]  shadow-[0_4px_30px_rgba(0,0,0,0.16)] bg-gradient-to-b from-[#292929] to-[#000000]">
                        <div className="flex flex-col items-center justify-center w-full h-full md:h-[557px] gap-9 md:gap-12">
                            <img
                                className="h-7"
                                src={`${imageBaseUrl}/landingpage/light-logo.svg`}
                            />
                            <img
                                className="h-28 object-none"
                                src={`${imageBaseUrl}/verification/group-flags.svg`}
                            />
                            <div className="font-inter text-white text-center px-4 sm:!px-0 sm:w-[498px]">
                                <p className="text-xl font-semibold">
                                    Welcome to JunoMoney!
                                </p>
                                <p className="mt-2 text-base">
                                    Congratulations on opening your JunoMoney
                                    account! To enjoy all the features make sure
                                    to verify your account.
                                </p>
                            </div>
                            <div className="font-semibold flex flex-col !gap-5 items-center text-base">
                                <div
                                    onClick={() =>
                                        dispatch(
                                            setShowVerificationModal('start')
                                        )
                                    }
                                    className={`jncAuth__Button w-full rounded-full px-8 py-3 text-[#18181B] font-semibold cursor-pointer full-width bg-white`}
                                >
                                    Begin Verification
                                </div>
                                <p
                                    onClick={() =>
                                        dispatch(setShowVerificationModal(''))
                                    }
                                    className=" text-white cursor-pointer"
                                >
                                    Verify Later
                                </p>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            {['start', KYCSTATUSES.retry].includes(showVerificationModal) && (
                <SumsubVerification
                    start={['start', KYCSTATUSES.retry].includes(
                        showVerificationModal
                    )}
                    onClose={() => dispatch(setShowVerificationModal(''))}
                // newID={kycStatus === KYCSTATUSES.retry}
                />
            )}
        </>
    )
}

export default JncHeader
