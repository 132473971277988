import React from 'react'
import { Dialog } from '@mui/material'
import JncButton from '../../../client/components/global/JncButton'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const JunoVerifyBusinessModal = (props) => {
    const { open, onClose } = props
    const clientData = useSelector((state) => state.juno.clientData)
    const image_base_url = useSelector((state) => state.config.image_base_url)
    const navigate = useNavigate()
    const { uploadDocuments, accountPurpose, transactionActivity } =
        clientData?.userRegistrationState || {}
    const isBusinessAccount = clientData?.accountType === 'Business'
    const onVerify = () => {
        let currentState
        if (isBusinessAccount && !uploadDocuments)
            currentState = 'uploadDocuments'
        else if (!accountPurpose) currentState = 'accountPurpose'
        else if (!transactionActivity) currentState = 'transactionActivity'

        if (currentState) {
            navigate(
                `/register/${isBusinessAccount ? 'business' : 'personal'}`,
                {
                    state: { step: currentState },
                }
            )
        }
        onClose()
    }

    const dialogContentComponent = () => {
        return (
            <div className="flex flex-col">
                <div className="flex justify-center items-center flex-col p-6">
                    <div className="h-20 p-3 bg-[#f3f3f5] rounded-[99px] border-8 border-neutral-50 justify-center items-center gap-2.5 inline-flex mb-8">
                        <img
                            src={`${image_base_url}/common/verify-business-icon.svg`}
                            alt=""
                        />
                    </div>
                    <p className="font-semibold text-xl text-center mb-1">
                        {!(
                            accountPurpose &&
                            transactionActivity &&
                            (isBusinessAccount ? uploadDocuments : true)
                        ) && (
                                <p>
                                    Verify your {isBusinessAccount ? 'business' : 'personal details.'}
                                </p>
                            )}

                        {(accountPurpose && transactionActivity &&
                            (isBusinessAccount ? uploadDocuments : true)) && (
                                <p>
                                    {isBusinessAccount ? "Your business is under verification." : "Your details are under verification"}
                                </p>
                            )}
                    </p>
                    <p className="font-normal text-base text-[#3F3F46] text-center px-6">
                        {!(
                            accountPurpose &&
                            transactionActivity &&
                            (isBusinessAccount ? uploadDocuments : true)
                        ) && (
                                <p>
                                    In order to execute any transaction we require you to
                                    validate your {isBusinessAccount ? 'business' : 'personal details.'}.
                                </p>
                            )}

                        {(accountPurpose && transactionActivity &&
                            (isBusinessAccount ? uploadDocuments : true)) && (
                                <p>
                                    We are currently verifying your {isBusinessAccount && "business"} details. This may take up to {isBusinessAccount ? "48" : "24"} hours to process.
                                </p>
                            )}
                    </p>
                </div>
            </div>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <>
                <div className="border-t-2 bg-[#F4F4F5] p-6">
                    <JncButton
                        text={
                            (clientData?.accountType !== 'Business' ||
                                uploadDocuments) &&
                                accountPurpose &&
                                transactionActivity
                                ? 'I Understand'
                                : 'Verify'
                        }
                        onClickCall={onVerify}
                        className={'w-full'}
                    />
                </div>
            </>
        )
    }
    return (
        <Dialog
            onClose={onClose}
            open={open}
            PaperProps={{
                className: '!rounded-xl w-[422px] !h-auto',
            }}
        >
            <div className="border-t-[3px] border-[#70707B] ">
                {dialogContentComponent()}
                {dialogActionsComponent()}
            </div>
        </Dialog>
    )
}

export default JunoVerifyBusinessModal
