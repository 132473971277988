import React, { useState } from 'react'
import JunoClientActionModal from '../table/JunoClientActionModal'
import CloseIcon from '@mui/icons-material/Close'
import JncButton from '../global/JncButton'
import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import { apiService } from '../../../../common/apiCallService'
import { useSelector } from 'react-redux'

const VerificationIssue = ({ open, onClose }) => {
    const [contactMessage, setContactMessage] = useState('')
    const [isFirstModal, setIsFirstModal] = useState(true)
    const [isContactSupport, setIsContactSupport] = useState(false)
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const clientData = useSelector((state) => state?.juno?.clientData)
    const api_url = useSelector((state) => state.config.api_url)

    const openContactForm = () => {
        setIsFirstModal(false)
        setIsContactSupport(true)
    }

    const handleConfirm = () => {
        setLoading(true)
        if (contactMessage) {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                    '/restapi/client-signup',
                {
                    message: contactMessage,
                    junoClientEmail: clientData?.clientEmail,
                    name: clientData?.name,
                },
                async (data) => {
                    if (data) {
                        setLoading(false)
                        setIsContactSupport(false)
                        setIsConfirmed(true)
                    }
                },
                (err) => {
                    console.log(err)
                    setLoading(false)
                    setError(err)
                }
            )
        } else {
            setLoading(false)
            setError('Message is required')
        }
    }

    const onChangeInput = (e) => {
        setContactMessage(e.target.value)
    }

    const handleModalClose = () => {
        onClose()
        setIsFirstModal(true)
        setIsContactSupport(false)
        setIsConfirmed(false)
        setContactMessage('')
        setError('')
    }

    const dialogContentComponent = () => {
        return (
            <div>
                {isFirstModal && (
                    <div className="flex justify-center items-center flex-col validationModal">
                        <div className="rounded-full bg-[#FEF3F2] p-3">
                            <div className=" bg-[#FEE4E2] rounded-full p-3 ">
                                <div className="rounded-full flex items-center justify-center">
                                    <CloseIcon className="!w-10 !h-10 text-[#D92D20] m-0" />
                                </div>
                            </div>
                        </div>
                        <p className="validationModal font-semibold mt-8">
                            Account Verification Issue
                        </p>
                        <p className="validationModal-subtitle">
                            We have encountered an issue when trying to process
                            your KYC information. Please contact support for
                            advice.
                        </p>
                    </div>
                )}
                {isContactSupport && (
                    <div>
                        <div className="flex  justify-between">
                            <p className="font-semibold text-2xl">
                                Contact Support
                            </p>
                            <button
                                onClick={handleModalClose}
                                type="button"
                                className="jncModal__close"
                            >
                                <ClearIcon />
                            </button>
                        </div>

                        <div className="mt-12">
                            <p>Message:</p>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeInput(e)}
                                    value={contactMessage}
                                    name="contactMessage"
                                    className="w-full bg-[#f3f3f5] p-2 rounded-lg resize-none h-[200px]"
                                    type="text"
                                    placeholder="If you are having issues with your KYC please contact support for help..."
                                />
                            </div>
                        </div>
                        <p className="text-red-500">
                            {error && 'Message is required'}
                        </p>
                    </div>
                )}
                {isConfirmed && (
                    <div className="flex justify-center items-center flex-col validationModal">
                        <div className="rounded-full bg-[#ECFDF3] p-3">
                            <div className=" bg-[#D1FADF] rounded-full p-3 ">
                                <div className="w-10 h-10 bg-[#039855] rounded-full flex items-center justify-center">
                                    <DoneIcon className="!w-10 !h-10 text-[#FFFFFF] m-0" />
                                </div>
                            </div>
                        </div>
                        <p className="validationModal text-xl font-semibold mt-8">
                            Your message have been sent!
                        </p>
                    </div>
                )}
            </div>
        )
    }
    const dialogActionsComponent = () => {
        return (
            <>
                {isFirstModal && (
                    <div className="jncModal__resp w-full">
                        <JncButton
                            text={
                                <p className="!text-[#3e3e46] font-medium">
                                    Contact support
                                </p>
                            }
                            onClickCall={openContactForm}
                            className={'w-full !bg-[#d1d1d6]'}
                        />
                    </div>
                )}
                {isContactSupport && (
                    <div className="jnc-acc-verificatio-btn">
                        <JncButton
                            text={<p className="font-medium">Confirm</p>}
                            onClickCall={handleConfirm}
                            className={'w-full'}
                            loading={loading}
                        />
                    </div>
                )}
                {isConfirmed && (
                    <div className="jncModal__resp w-full">
                        <JncButton
                            text={<p className=" font-medium">Continue</p>}
                            onClickCall={handleModalClose}
                            className={'w-full'}
                        />
                    </div>
                )}
            </>
        )
    }
    return (
        <div>
            <JunoClientActionModal
                dialogClassName={`${
                    isFirstModal && 'jn-acc-verification-delete'
                } ${isConfirmed && 'jn-acc-verification-message-sent'}`}
                onClose={handleModalClose}
                states={{
                    start: true,
                    confirm: false,
                }}
                open={open}
                dialogContent={dialogContentComponent()}
                dialogActions={dialogActionsComponent()}
            />
        </div>
    )
}

export default VerificationIssue
