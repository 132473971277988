import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { apiService } from '../../../../common/apiCallService'
import { setClientData } from '../../../../Redux/actions'

const VerifyClientDetailsCard = () => {
    const image_base_url = useSelector((state) => state.config.image_base_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const api_url = useSelector((state) => state.config.api_url)
    const dispatch = useDispatch()

    const [progress, setProgress] = useState(0)


    useEffect(() => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-logedIn-client',
            {},
            async (data) => {
                if (data) {
                    dispatch(setClientData(data))
                }
            },
            (err) => {
                console.log(err)
            }
        )
    }, [])

    useEffect(() => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-logedIn-client',
            {},
            async (data) => {
                if (data) {
                    dispatch(setClientData(data))
                }
            },
            (err) => {
                console.log(err)
            }
        )
    }, [])

    useEffect(() => {
        calculateProgress()
    }, [clientData])

    const navigate = useNavigate()
    
    const calculateProgress = () => {
        let progress = 0; 
    
        if (clientData?.accountType === "Business") {
            progress = 40; 
            const userRegistrationState = clientData?.userRegistrationState;
    
            if (userRegistrationState?.uploadDocuments) {
                progress += 20;
            }
            if (userRegistrationState?.accountPurpose) {
                progress += 20;
            }
            if (userRegistrationState?.transactionActivity) {
                progress += 20;
            }
        } else if (clientData?.accountType === "Personal") {
            progress = 60; 
            const userRegistrationState = clientData?.userRegistrationState;
    
            if (userRegistrationState?.accountPurpose) {
                progress += 20;
            }
            if (userRegistrationState?.transactionActivity) {
                progress += 20;
            }
        }
    
        setProgress(progress); 
    };
    


    const handleNavigateToBusinessDoc = () => {
        const { uploadDocuments, accountPurpose, transactionActivity } =
            clientData?.userRegistrationState || {};
    
        if (clientData?.accountType === "Business") {
            let currentState = "uploadDocuments";
    
            if (!uploadDocuments) {
                currentState = "uploadDocuments";
            } else if (!accountPurpose) {
                currentState = "accountPurpose";
            } else if (!transactionActivity) {
                currentState = "transactionActivity";
            }
    
            navigate("/register/business", {
                state: { step: currentState },
            });
        } else if (clientData?.accountType === "Personal") {
            let currentState = "accountPurpose";
    
            if (!accountPurpose) {
                currentState = "accountPurpose";
            } else if (!transactionActivity) {
                currentState = "transactionActivity";
            }
    
            navigate("/register/personal", {
                state: { step: currentState },
            });
        }
    };
    

    return (
        
        <div
            className={`flex flex-row justify-between items-center gap-4 h-[84px] lg:h-[144px] 
                       !p-3 lg:!p-8 rounded-[12px] mb-4 bg-black relative`}
            style={{
                backgroundImage: `url(${image_base_url}/common/texture-metal.png)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className="flex items-center gap-6">
                <div
                    className="relative flex justify-center items-center w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] rounded-full"
                    style={{
                        background: `conic-gradient(white ${
                            progress * 3.6
                        }deg, #18181B 0deg)`,
                    }}
                >
                    <div className="absolute flex justify-center items-center w-[56px] h-[56px] lg:w-[76px] lg:h-[76px] rounded-full bg-black">
                        <span className="text-[14px] lg:text-[24px] font-medium text-white">
                            {progress}%
                        </span>
                    </div>
                </div>

                <div>
                    <p className="font-inter text-[14px] lg:text-[24px] font-medium sm:leading-4 text-left text-white">
                        In order to start operating, complete account setup
                    </p>
                </div>
            </div>

            <div className="block lg:hidden">
                <ArrowForwardIosIcon
                    className="text-white"
                    onClick={handleNavigateToBusinessDoc}
                />
            </div>

            <div className="hidden lg:block">
            <button
                    className="w-[197px] h-[36px] p-[6px] rounded-[99px] bg-white text-[#18181B] 
                               hover:!bg-[#18181B] hover:text-white border text-[16px]"
                    onClick={handleNavigateToBusinessDoc}
                >
                    {clientData?.accountType === "Business"
                        ? "Verify Business Details"
                        : clientData?.accountType === "Personal"
                        ? "Verify Personal Details"
                        : "Verify Details"}
                </button>
            </div>
        </div>
    )
}

export default VerifyClientDetailsCard
