
import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterSelectField from './RegisterSelectField'
import JncButton from '../../../client/components/global/JncButton'
import { useJunoCommonFunctions } from '../../../helpers'
import { JunoConfirmModal } from './JunoConfirmModal'
import SearchbleMultiSelector from './SearchbleMultiSelector'
import { fiatCurrencyList } from '../../../helpers'

export default function TransactionActivity(props) {
    const { onClick, onBack, handleSaveClick } = props
    const [formState, setFormState] = useState({
        inboundTransactions: '',
        inboundUsdValue: '',
        inboundCurrencies: [],
        additionalInboundCurrencies: '',
        inboundJurisdictions: '',
        inboundEntities: '',
        outboundTransactions: '',
        outboundUsdValue: '',
        outboundCurrencies: [],
        additionalOutboundCurrencies: '',
        outboundJurisdictions: '',
        outboundEntities: '',
        fxTransactions: '',
        fxUsdValue: '',
        fxPairs: [],
        errors: {}
    })
    
    const [openConfirm, setOpenConfirm] = useState(false)
    const { useWindowWidth, handleRegistrationStages } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()
    const [loading, setLoading] = useState(false)
    const [alertMsg, setAlertMsg] = useState(null)
    const [countries, setCountries] = useState([])
    const { getCountries } = useJunoCommonFunctions()


    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
            errors: { ...prevState.errors, [name]: '' }
        }))
    }

    const handleMultiSelectChange = (name, value) => {
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
            errors: { ...prevState.errors, [name]: '' }
        }))
    }

    const numberOfTransactionsOption = [
        { value: '1-500', label: '1-500' },
        { value: '500-5000', label: '500-5,000' },
        { value: '5000-25000', label: '5,000-25,000' },
        { value: '25000-100000', label: '25,000-100,000' },
        { value: '100000-1000000', label: '100,000-1,000,000' },
        { value: '1000000+', label: '1,000,000+' },
    ]

    function getFormattedOptions(isAmount) {

        if (!isAmount) {
            return numberOfTransactionsOption;
        } else {
            return numberOfTransactionsOption.map(option => {
                const parts = option.label.split('-');
                const formattedOptions = parts.map(part => `$${part}`).join('-');

                return {
                    value: formattedOptions,
                    label: formattedOptions
                };
            });
        }

        return [];
    }

    const transactionsCurrenciesOptions = fiatCurrencyList.map(currency => ({
        value: currency.text,
        label: `${currency.fullForm} (${currency.text})`
    }))

    const fxPairsOptions = []
    for (let i = 0; i < fiatCurrencyList.length; i++) {
        for (let j = i + 1; j < fiatCurrencyList.length; j++) {
            const pair = `${fiatCurrencyList[i].text}-${fiatCurrencyList[j].text}`;
            fxPairsOptions.push({ value: pair, label: `${fiatCurrencyList[i].text} to ${fiatCurrencyList[j].text}` });
        }
    }

    const handleContinue = () => {
        if (isFormValid()) {
            setOpenConfirm(true)
        }
    }

    const onSubmit = async () => {
        if (!isFormValid()) {
            setAlertMsg('All fields are required.')
            return
        }
        setLoading(true)
        const payload = {
            inbound: {
                estimatedNumberPerMonth: formState.inboundTransactions,
                averageUsdValue: formState.inboundUsdValue.replace(/\$/g, '').trim(),
                mainCurrencies: formState.inboundCurrencies,
                additionalCurrencies: formState.additionalInboundCurrencies,
                jurisdictionsReceivingFundsFrom: formState.inboundJurisdictions,
                topEntitiesSendingFunds: formState.inboundEntities,
            },
            outbound: {
                estimatedNumberPerMonth: formState.outboundTransactions,
                averageUsdValue: formState.outboundUsdValue.replace(/\$/g, '').trim(),
                mainCurrencies: formState.outboundCurrencies,
                additionalCurrencies: formState.additionalOutboundCurrencies,
                jurisdictionsSendingFundsTo: formState.outboundJurisdictions,
                topBeneficiariesReceivingFunds: formState.outboundEntities,
            },
            fx: {
                numberPerMonth: formState.fxTransactions,
                averageUsdValue: formState.fxUsdValue.replace(/\$/g, '').trim(),
                mainFxPairs: formState.fxPairs,
            },
        }

        try {
            await handleRegistrationStages('transaction-activity', { transactionActivity: payload });
            onClick()
        } catch (error) {
            console.error('Error:', error);
            setAlertMsg(error);
        } finally {
            setAlertMsg(null)
            setLoading(false);
            setOpenConfirm(false);
        }
    }

    const isFormValid = () => {
        const errors = {};
        const nonRequiredFields = ['additionalInboundCurrencies', 'additionalOutboundCurrencies'];
        Object.entries(formState).forEach(([key, value]) => {
            if (key === 'errors') return;
            if (nonRequiredFields.includes(key)) return;
            if (typeof value === 'string' && value.trim() === '') {
                errors[key] = 'This field is required';
            } else if (Array.isArray(value) && value.length === 0) {
                errors[key] = 'This field is required';
            }
        });

        setFormState((prevState) => ({
            ...prevState,
            errors
        }));

        return Object.keys(errors).length === 0;
    };
    const fetchCountries = async () => {
        try {
            const countries = await getCountries();
            const mappedCountries = countries?.allCountries.map(item => { return { label: item.name, value: item.name } });
            setCountries(mappedCountries);
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    useEffect(() => {
        fetchCountries()
    }, [])


    return (
        <div className="md:w-[460px] w-full h-full md:h-fit rounded-lg bg-white">
            <JunoConfirmModal
                open={openConfirm}
                loading={loading}
                onClose={() => setOpenConfirm(false)}
                onSubmit={onSubmit}
                title="Confirm Document Authorization"
                subtitle="By clicking below, you confirm that you have the legal authority to share the attached business documents and that you are an authorized representative of the organization."
            />
            {windowWidth > 768 && (
                <div className="px-[14px] h-[76px] lg:h-[108px] py-[22px] border-b-[1px] justify-between items-center flex">
                    <div className="flex gap-2">
                        {/* <span
                            onClick={onBack}
                            className="self-center cursor-pointer"
                        >
                            <Icon id="arrowLeft" />
                        </span> */}
                        <p className="text-[#18181B] font-semibold text-2xl ">
                            Transaction activity
                        </p>
                    </div>
                    <button className="flex items-center justify-center text-sm text-gray-900 bg-gray-200 h-8 rounded-full font-medium px-4 py-[6px]" onClick={handleSaveClick}>
                        {windowWidth < 1100 ? 'Skip' : 'Save & Continue'}
                    </button>
                </div>
            )}
            <div className="flex flex-col md:h-[500px] justify-start w-full overflow-y-auto scroll-smooth relative">
                {alertMsg && (
                    <div className="alert alert-danger" role="alert">
                        {alertMsg}
                    </div>
                )}
                <form className="py-[22px]">
                    {/*Inbound transacitons */}
                    <p className="text-[18px] mb-[12px] px-4 text-[#18181B]">
                        Inbound
                    </p>
                    <RegisterSelectField
                        label="Estimated number of inbound transactions per month"
                        required
                        value={formState.inboundTransactions}
                        onChange={handleInputChange}
                        placeholder="Select transaction count"
                        options={getFormattedOptions(false)}
                        name={'inboundTransactions'}
                        error={formState.errors.inboundTransactions}
                    />
                    <RegisterSelectField
                        label="Average USD value of each inbound transaction"
                        required
                        value={formState.inboundUsdValue}
                        onChange={handleInputChange}
                        placeholder="Select amount"
                        options={getFormattedOptions(true)}
                        name={'inboundUsdValue'}
                        error={formState.errors.inboundUsdValue}
                    />
                    <RegisterSelectField
                        label="Main Inbound Currencies"
                        required
                        isMultiSelect
                        name='inboundCurrencies'
                        value={formState.inboundCurrencies}
                        onChange={(e) =>
                            handleMultiSelectChange(
                                'inboundCurrencies',
                                e.target.value
                            )
                        }
                        placeholder="Select currencies you operate with"
                        options={transactionsCurrenciesOptions}
                        error={formState.errors.inboundCurrencies}
                    />
                    <div className="jncAuth__field mb px-4 mt-[10px]">
                        <p className="jncAuth__field-label">
                            Additional inbound currency requirements
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1.2rem',
                                    lineHeight: 1,
                                }}
                            >
                            </Typography>
                        </p>
                        <textarea
                            value={formState.additionalInboundCurrencies}
                            placeholder="List any additional inbound currencies that you require"
                            onChange={handleInputChange}
                            name="additionalInboundCurrencies"
                            rows={3}
                        />
                    </div>
                    <SearchbleMultiSelector

                        label="Top Jurisdictions you will receive funds from"
                        required
                        isMultiSelect={true}
                        name='inboundJurisdictions'
                        value={formState.inboundJurisdictions}
                        onChange={(e) =>
                            handleMultiSelectChange(
                                'inboundJurisdictions',
                                e.map(obj => obj.value).join(', ')
                            )
                        }
                        placeholder="Select jurisdiction you operate with"
                        options={countries}
                        error={formState.errors.inboundJurisdictions}
                    />
                    <div className="jncAuth__field mb px-4 mt-[10px]">
                        <p className="jncAuth__field-label">
                            Top entities/individuals that will send funds into
                            your Juno Money Account
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1.2rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <textarea
                            value={formState.inboundEntities}
                            placeholder="List full names with commas"
                            onChange={handleInputChange}
                            name="inboundEntities"
                            rows={3}
                        />
                        {formState.errors.inboundEntities && (
                            <p className="text-red-500 text-sm pb-2">
                                *{formState.errors.inboundEntities}
                            </p>
                        )}
                    </div>
                    <div className="h-[1px] w-full bg-[#CDD5DF] mb-[22px]"></div>
                    {/*Outbound transactions*/}
                    <p className="text-[18px] mb-[12px] px-4 text-[#18181B]">
                        Outbound
                    </p>
                    <RegisterSelectField
                        label="Estimated number of outbound transactions per month"
                        required
                        value={formState.outboundTransactions}
                        onChange={handleInputChange}
                        placeholder="Select transaction count"
                        options={getFormattedOptions(false)}
                        name={'outboundTransactions'}
                        error={formState.errors.outboundTransactions}
                    />
                    <RegisterSelectField
                        label="Average USD value of each outbound transaction"
                        required
                        value={formState.outboundUsdValue}
                        onChange={handleInputChange}
                        placeholder="Select amount"
                        options={getFormattedOptions(true)}
                        name={'outboundUsdValue'}
                        error={formState.errors.outboundUsdValue}
                    />
                    <RegisterSelectField
                        label="Main Outbound Currencies"
                        required
                        isMultiSelect
                        value={formState.outboundCurrencies}
                        onChange={(e) =>
                            handleMultiSelectChange(
                                'outboundCurrencies',
                                e.target.value
                            )
                        }
                        placeholder="Select currencies you operate with"
                        options={transactionsCurrenciesOptions}
                        error={formState.errors.outboundCurrencies}
                    />
                    <div className="jncAuth__field mb px-4 mt-[10px]">
                        <p className="jncAuth__field-label">
                            Additional outbound currency requirements
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1.2rem',
                                    lineHeight: 1,
                                }}
                            >
                            </Typography>
                        </p>
                        <textarea
                            value={formState.additionalOutboundCurrencies}
                            placeholder="List any additional outbound currencies that you require"
                            onChange={handleInputChange}
                            name="additionalOutboundCurrencies"
                            rows={3}
                        />
                    </div>
                    <SearchbleMultiSelector
                        label="Top Jurisdictions you will receive funds from"
                        required
                        isMultiSelect={true}
                        name='outboundJurisdictions'
                        value={formState.outboundJurisdictions}
                        onChange={(e) =>
                            handleMultiSelectChange(
                                'outboundJurisdictions',
                                e.map(obj => obj.value).join(', ')
                            )
                        }
                        placeholder="Select jurisdiction you operate with"
                        options={countries}
                        error={formState.errors.outboundJurisdictions}
                    />
                    <div className="jncAuth__field mb px-4 mt-[10px]">
                        <p className="jncAuth__field-label">
                            Top beneficiaries that will receive funds from your
                            Juno Money Account
                            <Typography
                                component="span"
                                sx={{
                                    color: '#3F3F46',
                                    fontSize: '1.2rem',
                                    lineHeight: 1,
                                }}
                            >
                                *
                            </Typography>
                        </p>
                        <textarea
                            value={formState.outboundEntities}
                            placeholder="List full names with commas"
                            onChange={handleInputChange}
                            name="outboundEntities"
                            rows={3}
                        />
                        {formState.errors.outboundEntities && (
                            <p className="text-red-500 text-sm pb-2">
                                *{formState.errors.outboundEntities}
                            </p>
                        )}
                    </div>
                    <div className="h-[1px] w-full bg-[#CDD5DF] mb-[22px]"></div>
                    {/*FX transactions*/}
                    <p className="text-[18px] mb-[12px] px-4 text-[#18181B]">
                        FX
                    </p>
                    <RegisterSelectField
                        label="Number of FX transactions per month"
                        required
                        value={formState.fxTransactions}
                        onChange={handleInputChange}
                        placeholder="Select transaction count"
                        options={getFormattedOptions(false)}
                        name={'fxTransactions'}
                        error={formState.errors.fxTransactions}
                    />
                    <RegisterSelectField
                        label="Average USD value of each FX transaction"
                        required
                        value={formState.fxUsdValue}
                        onChange={handleInputChange}
                        placeholder="Select amount"
                        options={getFormattedOptions(true)}
                        name={'fxUsdValue'}
                        error={formState.errors.fxUsdValue}
                    />
                    <RegisterSelectField
                        label="Main FX pairs"
                        required
                        isMultiSelect
                        value={formState.fxPairs}
                        onChange={(e) =>
                            handleMultiSelectChange('fxPairs', e.target.value)
                        }
                        placeholder="Select main FX pairs"
                        options={fxPairsOptions}
                        name='fxPairs'
                        error={formState.errors.fxPairs}
                    />
                    <div className="px-4 mt-[22px]">
                        <JncButton
                            text="Continue"
                            className="w-full"
                            onClickCall={handleContinue}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
