import React from 'react';
import Typography from '@mui/material/Typography'
import Select from "react-select";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const SearchbleMultiSelector = ({ 
    label,
    isMultiSelect,
    required = false,
    onChange,
    placeholder = "Select an option",
    options = [],
    name = "",
    cssClass='px-4',
    error }) => {

    const DropdownIndicator = (props) => {
        const { selectProps } = props;
        return (
            <div style={{ paddingRight: 8 }} >
                {selectProps.menuIsOpen ? (
                    <ArrowDropUpIcon style={{ color: "#0000008a" }} />
                ) : (
                    <ArrowDropDownIcon style={{ color: "#0000008a" }} />
                )}
            </div>
        );
    };

    return (
        <div className={`jncAuth__field mb-[12px] ${cssClass}`}>
            {label && (
                <p className="jncAuth__field-label">
                    {label}
                    {required && (
                        <Typography
                            component="span"
                            sx={{
                                color: "#3F3F46",
                                fontSize: "1.2rem",
                                lineHeight: 1,
                            }}
                        >
                            *
                        </Typography>
                    )}
                </p>
            )}
            <Select
                className="basic-multi-select text-black border-[#e5e7eb] rounded-lg"
                classNamePrefix="searchble_multi_select"
                placeholder={placeholder}
                isMulti={isMultiSelect}
                options={options}
                onChange={onChange}
                name={name}
                isClearable
                components={{ DropdownIndicator }}
            />
            {error &&
                    <p className="text-red-500 text-sm ">
                        *{error}
                    </p>}
        </div>
    );
}
export default SearchbleMultiSelector;