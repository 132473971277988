export const ValidStatus = [
    'PrivateClient:TransactionCompleted',
    'PrivateClient:RequestForApproval',
    'PrivateClient:Rejected',
    'PrivateClient:ErrorEncountered',
]

export const TransactionTypes = [
    'Deposit',
    'FX',
    'Payment Out',
    'Transfer',
    'Withdrawal',
]

export const CryptoTransactionTypes = [
    'Buy',
    'Sell',
    'Exchange',
    'Send',
    'Receive'
]

export const CurrencyTypes = [
    "Fiat",
    "Crypto"
]
export const ErrorTransactions = [
    'PrivateClient:Rejected',
    'PrivateClient:ErrorEncountered',
    'BuyFlow:ErrorEncountered',
    'SellFlow:ErrorEncountered',
    'SendFlow:ErrorEncountered',
    'ReceiveFlow:ErrorEncountered',
    'ExchangeFlow:ErrorEncountered',
]

export const COLORS = ["#3CCB7F", "#A48AFB", "#FDB022", "#22CCEE", "#E478FA", "#D0D5DD"];

export const erc20Tokens = ['ETH', 'DAI', 'USDT', 'USDC'];

export const KYCSTATUSES = {
    "not-started": "not-started",
    failed: "failed",
    completed: "completed",
    retry: "retry",
    pending: "pending"
}

export const documentsFeilds = [
    {
        label: 'Online Commercial Registry Extract',
        key: 'onlineCommercialRegistryExtract',
    },
    {
        label: 'Certificate of Incorporation',
        key: 'certificateOfIncorporation',
    },
    {
        label: 'Articles/Memorandum of Association',
        key: 'articlesMemorandumOfAssociation',
    },
    {
        label: 'Proof of Business Address',
        key: 'proofOfBusinessAddress',
        toolTipText:
            'Utility bill issued within the last 90 days, referring to the principal place of business',
    },
    {
        label: 'Signed and Dated Company Structure Chart',
        key: 'signedAndDatedCompanyStructureChart',
    },
    {
        label: 'Ownership Structure / Shareholders Register',
        key: 'ownershipStructureShareholdersRegister',
        toolTipText: 'All shareholders holding 20% or more of the ownership of the company.',
    },
    {
        label: 'Directors Register',
        key: 'directorsRegister',
        toolTipText:
            'An official register of directors/recent extract from the relevant business register/certificate of incumbency/operating agreement',
    },
    {
        label: 'Bank Statement in the Company’s Name',
        key: 'bankStatementInTheCompanysName',
        toolTipText: 'Issued within the last 90 days with visible transaction flows',
    },
    {
        label: 'Operating Licences',
        key: 'operatingLicences',
    },
];