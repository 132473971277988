import React, { useState } from 'react'
import { useJunoCommonFunctions } from '../../../helpers'
import Icon from '../../../client/icons/Icon'
import JncButton from '../../../client/components/global/JncButton'
import {
    Checkbox,
    Chip,
    FormControl,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'

const purposeData = [
    { value: 'firstInbound', label: '1st party inbound payments' },
    { value: 'firstOutbound', label: '1st party outbound payments' },
    { value: 'thirdInbound', label: '3rd party inbound payments' },
    { value: 'thirdOutbound', label: '3rd party outbound payments' },
    { value: 'fx', label: 'FX' },
]

const JunoAccountPurpose = ({ onBack, onClick, handleSaveClick }) => {
    const { handleRegistrationStages } = useJunoCommonFunctions()

    const [form, setForm] = useState({
        accountPurpose: [],
        flowOfFunds: '',
        sourceOfFunds: '',
    })
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setForm((prev) => ({
            ...prev,
            [name]:
                name === 'accountPurpose' && typeof value === 'string'
                    ? value.split(',')
                    : value,
        }))

        // Clear the error for the field being updated
        setErrors((prev) => ({
            ...prev,
            [name]: '',
        }))
    }

    const validateForm = () => {
        const newErrors = {}
        if (!form.accountPurpose || form.accountPurpose.length === 0) {
            newErrors.accountPurpose = 'Please select at least one account purpose.'
        }
        if (!form.flowOfFunds?.trim()) {
            newErrors.flowOfFunds = 'Please explain the flow of funds.'
        }
        if (!form.sourceOfFunds?.trim()) {
            newErrors.sourceOfFunds = 'Please explain the source of funds.'
        }
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const onSubmit = async () => {
        if (!validateForm()) {
            return
        }
        setLoading(true)
        try {
            await handleRegistrationStages('account-purpose', {
                accountPurpose: form.accountPurpose,
                flowOfFunds: form.flowOfFunds,
                sourceOfFunds: form.sourceOfFunds,
            })
            onClick()
        } catch (error) {
            console.error('Error:', error)
            setErrors({ form: error.message || 'An error occurred.' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="md:w-[460px] w-full h-full md:h-fit rounded-lg bg-white">
            <div className="md:flex hidden justify-between items-center py-[22px] px-[14px] border-b-[1px] border-[#CDD5DF]">
                <div className="flex gap-3">
                    {/* <span onClick={onBack} className="self-center cursor-pointer">
                        <Icon id="arrowLeft" />
                    </span> */}
                    <p className="text-[#18181B] font-semibold text-2xl">
                        Account Purpose
                    </p>
                </div>
                <Chip
                    label="Save & Continue"
                    className="!bg-gray-200 !text-sm !text-gray-900 !font-medium !px-4"
                    onClick={handleSaveClick}
                />
            </div>

            <form className="flex flex-col gap-8 px-4 py-[22px]">
                {errors.form && (
                    <div className="alert alert-danger" role="alert">
                        {errors.form}
                    </div>
                )}
                <div className="flex flex-col gap-6">
                    <FormControl fullWidth className="flex flex-col gap-[6px]">
                        <p className="text-sm text-gray-700 font-medium">
                            Account Purpose*
                        </p>
                        <Select
                            value={form.accountPurpose || []}
                            id="accountPurpose"
                            name="accountPurpose"
                            onChange={handleInputChange}
                            displayEmpty
                            multiple
                            sx={{
                                border: '1px solid #E5E7EB',
                                borderRadius: '8px',
                                padding: '10px',
                                '.MuiSelect-outlined': {
                                    padding: '0',
                                },
                                backgroundColor: 'white',
                            }}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return (
                                        <span className="text-gray-500">
                                            Select reason opening account
                                        </span>
                                    )
                                }
                                return selected
                                    .map(
                                        (selectedValue) =>
                                            purposeData.find(
                                                (item) =>
                                                    item.value === selectedValue
                                            )?.label || selectedValue
                                    )
                                    .join(', ')
                            }}
                        >
                            {purposeData.map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    <Checkbox
                                        checked={form.accountPurpose?.includes(
                                            value
                                        )}
                                    />
                                    <ListItemText primary={label} />
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.accountPurpose && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors.accountPurpose}
                            </p>
                        )}
                    </FormControl>
                    <FormControl fullWidth className="flex flex-col gap-[6px]">
                        <p className="text-sm text-gray-700 font-medium">
                            Please explain the flow of funds in/out of your Juno Money*
                        </p>
                        <TextField
                            placeholder="Enter text here"
                            value={form.flowOfFunds || ''}
                            name="flowOfFunds"
                            required
                            multiline
                            minRows={2}
                            maxRows={4}
                            onChange={handleInputChange}
                            error={!!errors.flowOfFunds}
                            helperText={errors.flowOfFunds}
                            className="w-full border-[#D1D1D6] border rounded-lg text-[48px]"
                        />
                    </FormControl>
                    <FormControl fullWidth className="flex flex-col gap-[6px]">
                        <p className="text-sm text-gray-700 font-medium">
                            Please explain the Source of Funds that will be sent into your Juno Money account*
                        </p>
                        <TextField
                            placeholder="Enter text here"
                            value={form.sourceOfFunds || ''}
                            name="sourceOfFunds"
                            required
                            multiline
                            minRows={2}
                            maxRows={4}
                            onChange={handleInputChange}
                            error={!!errors.sourceOfFunds}
                            helperText={errors.sourceOfFunds}
                            className="w-full border-[#D1D1D6] border rounded-lg text-[48px]"
                        />
                    </FormControl>
                </div>
                <JncButton
                    text="Continue"
                    className="w-full"
                    disabled={loading}
                    onClickCall={onSubmit}
                    loading={loading}
                />
            </form>
        </div>
    )
}

export default JunoAccountPurpose
