import React from 'react'
import { Dialog } from '@mui/material'
import JncButton from '../../../client/components/global/JncButton'
import ClearIcon from '@mui/icons-material/Clear'

export const JunoConfirmModal = (props) => {
    const { open, onClose, title, subtitle, loading, onSubmit } = props

    const dialogContentComponent = () => {
        return (
            <div className="flex flex-col">
                <button
                    onClick={onClose}
                    type="button"
                    className="self-end px-4 pt-4"
                >
                    <ClearIcon />
                </button>
                <div className="flex justify-center items-center flex-col p-6">
                    <p className="font-semibold text-xl text-center mb-1">
                        {title}
                    </p>
                    <p className="font-normal text-base text-[#3F3F46] text-center">
                        {subtitle}
                    </p>
                </div>
            </div>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <>
                <div className="border-t-2 bg-[#F4F4F5] p-6">
                    <JncButton
                        text="I confirm"
                        onClickCall={onSubmit}
                        className={'w-full'}
                        loading={loading}
                    />
                </div>
            </>
        )
    }
    return (
        <Dialog
            onClose={onClose}
            open={open}
            PaperProps={{
                className: '!rounded-xl w-[422px] !h-auto',
            }}
        >
            <div className="border-t-[3px] border-[#70707B] ">
                {dialogContentComponent()}
                {dialogActionsComponent()}
            </div>
        </Dialog>
    )
}
