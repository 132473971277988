import React, { useState } from 'react'
import { getStatus, useJunoCommonFunctions } from '../../../helpers'
import InputCopyButton from '../../components/global/InputCopyButton'
import { toFixedTrunc, getCurrencySymbol } from '../../../helpers'
import { ErrorTransactions } from '../../constant'
import JncPaymentConfirmation from '../../templates/JncPaymentConfirmation'
import Icon from '../../icons/Icon'
import { useSelector } from 'react-redux'
import BalanceFiatWithdraw from '../balances/actions/fiat/BalanceFiatWithdraw'

export default function TransactionMobileDialogWithdraw({ data }) {
    const { validateFreezedCurrencies, handleTransactionModalLogic } = useJunoCommonFunctions();
    const details = data && data.transactionDetails[0]
    const transactionFee = data && data.transactionFee
    const clientData = useSelector((state) => state.juno.clientData)
    const readOnly = clientData?.readOnly
    const [repeatData, setRepeatData] = useState('')
    const [repeatModal, setRepeatModal] = useState('')
    const kycStatus = localStorage.getItem("kycStatus")



    function openRepeatModal(row) {

        handleTransactionModalLogic(kycStatus, () => validateFreezedCurrencies(row.type, () => {
            setRepeatData(row)
            setRepeatModal(row.type)
        }))
    }
    const balanceBeforeDeduction = data.transactionFee
        ? Number(details.amount) + Number(data.transactionFee)
        : details.amount

    return (
        <div className="pb-[100px]">
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">Status:</p>
                <div className="d-flex">
                    <div
                        className={`jncTransactionsList__status ${getStatus(
                            data.status.code
                        )
                            .toLowerCase()
                            .replace('/', '')}`}
                    >
                        {getStatus(data.status.code)}
                    </div>
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Transaction Id:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {data.transactionId || '-'}
                    </p>
                    <InputCopyButton copyValue={data.transactionId} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Beneficiary name:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.beneficiaryName || '-'}
                    </p>
                    <InputCopyButton copyValue={details.beneficiaryName} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Beneficiary address:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.beneficiaryAddress || '-'}
                    </p>
                    <InputCopyButton copyValue={details.beneficiaryAddress} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Beneficiary country:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.beneficiaryCountry || '-'}
                    </p>
                    <InputCopyButton
                        copyValue={details.beneficiaryCountry}
                    />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">Bank name:</p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.bankName || '-'}
                    </p>
                    <InputCopyButton copyValue={details.bankName} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">Bank address:</p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.bankAddress || '-'}
                    </p>
                    <InputCopyButton copyValue={details.bankAddress} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">Bank country:</p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.bankCountry || '-'}
                    </p>
                    <InputCopyButton copyValue={details.bankCountry} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">IBAN:</p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.iban || '-'}
                    </p>
                    <InputCopyButton copyValue={details.iban} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">SWIFT:</p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.swift || '-'}
                    </p>
                    <InputCopyButton copyValue={details.swift} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">Sort code:</p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.sortCode || '-'}
                    </p>
                    <InputCopyButton copyValue={details.sortCode} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Beneficiary account number:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.beneficiaryAccountNumber || '-'}
                    </p>
                    <InputCopyButton
                        copyValue={details.beneficiaryAccountNumber}
                    />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    {!transactionFee ? 'Amount' : 'Amount before deduction'}:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {getCurrencySymbol(data.currency)}{' '}
                        {toFixedTrunc(balanceBeforeDeduction, 2) || '-'}
                    </p>
                    <InputCopyButton
                        copyValue={`$ ${toFixedTrunc(balanceBeforeDeduction, 2)}`}
                    />
                </div>
            </div>
            {transactionFee ? (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        Amount after deduction :
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {getCurrencySymbol(data.currency)}{' '}
                            {toFixedTrunc(details.amount, 2) || '-'}
                        </p>
                        <InputCopyButton
                            copyValue={`$ ${toFixedTrunc(
                                details.amount,
                                2
                            )}`}
                        />
                    </div>
                </div>
            ) : (
                ''
            )}
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">Reference:</p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.reference || '-'}
                    </p>
                    <InputCopyButton copyValue={details.reference} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Balance after transaction:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {getCurrencySymbol(data.currency)}{' '}
                        {toFixedTrunc(data.balanceAfterTransaction, 2)}
                    </p>
                    <InputCopyButton copyValue={data.balanceAfterTransaction} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Transaction Fee:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {getCurrencySymbol(data.currency)}{' '}
                        {toFixedTrunc(transactionFee, 2) || '-'}
                    </p>
                    <InputCopyButton copyValue={transactionFee} />
                </div>
            </div>
            {ErrorTransactions.includes(data.status.code) && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        {getStatus(data.status.code)}:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {data.status.message}
                        </p>
                        <InputCopyButton copyValue={data.status.message} />
                    </div>
                </div>
            )}
            <div className='fixed bottom-0 left-0 p-6 h-[100px] z-50 bg-white flex justify-evenly w-full'>
                <div className=''>
                    <button
                        type='button'
                        disabled={readOnly}
                        onClick={() => openRepeatModal(data)}
                        className={`jncRepeatBtn ${readOnly && 'jnc-btn-disable'
                            }`}
                    >
                        <Icon id='repeatAction' />
                    </button>
                    <span className='mt-2'>Repeat</span>
                </div>
                {data.status.code === 'PrivateClient:TransactionCompleted' && (
                    <JncPaymentConfirmation data={data} />
                )}
            </div>
            <BalanceFiatWithdraw
                open={repeatModal === 'Withdrawal'}
                onClose={() => setRepeatModal('')}
                row={repeatData}
            />
        </div>
    )
}
