import React, { useState, useEffect, useRef } from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import { useDispatch, useSelector } from 'react-redux'
import { apiService } from '../../../../../../common/apiCallService'
import {
    getCurrencySymbol,
    clearAllValuesInObj,
    validateAmount,
    getClientTransactionUrl,
    useJunoCommonFunctions,
    validateCurrency,
} from '../../../../../helpers'
import AttachmentsUpload from '../../../../components/form/AttachmentsUpload'
import SavedAccountsList from './SavedAccountsList'
import FieldsForEdit from './FieldsForEdit'
import {
    setErrorMessage,
    setSuccessMessage,
} from '../../../../../../Redux/actions/junoAction'
import JncCurrenciesTypeahead from '../../../../components/global/JncCurrenciesTypeahead'
import JncButton from '../../../../components/global/JncButton'
import WithdrawConfirm from '../../../../components/form/WithdrawalConfirm'
import { Typeahead } from 'react-bootstrap-typeahead'
import { JncSuccessModal } from '../../../../components/modals/JncSuccessModal'
import { SearchOutlined } from '@mui/icons-material'

export default function BalanceFiatWithdraw({
    onClose,
    open,
    row,
    accountDetails,
}) {
    const {
        getBalances,
        getSavedAccounts,
        saveAccountApi,
        updateAccountApi,
        getTransactions,
        getCountries,
    } = useJunoCommonFunctions()
    const savedAccounts = useSelector((state) => state.juno.savedAccounts)
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const api_url = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const clientTransactionFee = useSelector(
        (state) => state.juno.clientTransactionFee,
    )
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)
    const unfreezeBalances = useSelector((state) => state.juno.unfreezeFiatCurrencies)
    const dispatch = useDispatch()
    const [isConfirm, setIsConfirm] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [isFailed, setIsFailed] = useState(false)
    const [amountError, setAmountError] = useState('')
    const [currencyError, setCurrencyError] = useState("")
    const [attachments, setAttachments] = useState([])
    const [attachmentsError, setAttachmentsError] = useState('')
    const [attachmentUrls, setAttachmentUrls] = useState([])
    const [failedErr, setFailedErr] = useState('')
    const [countries, setCountries] = useState([])
    //--- for accounts
    const [isNewAccountComponent, setIsNewAccountComponent] = useState(
        savedAccounts && savedAccounts.length < 1,
    )
    const [isEditAccount, setIsEditAccount] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isChoosenAccount, setIsChoosenAccount] = useState(false)
    const [transactionIdFore2e, setTransactionIdFore2e] = useState('')
    //---
    const [selectedCurrency, setSelectedCurrency] = useState([])
    const [errorInputNames, setErrorsInputNames] = useState([])
    const [additionalFields, setAdditionalFields] = useState({ bankCountry: false, beneficiaryCountry: false, beneficiaryName: false })
    const [successModal, setSuccessModal] = useState(false)
    const [savedFilterAccounts, setSavedFilterAccounts] = useState(savedAccounts || []);
    const [savedAccountSearchValue, setSavedAccountSearchValue] = useState();
    const inputRef = useRef(null);

    const [formData, setFormData] = useState({
        beneficiaryName: '',
        beneficiaryAddress: '',
        bankName: '',
        bankAddress: '',
        beneficiaryAccountNumber: '',
        sortCode: '',
        swift: '',
        iban: '',
        additionalBankDetails: '',
        amount: '',
        reference: '',
        accountNickName: '',
        bankCountry: '',
        beneficiaryCountry: '',
        saveBankAccount: false,
        toCurrency: ''
    })
    const clientWithdrawalFee = clientTransactionFee.find(
        (e) => e.transactionType === 'Withdrawal',
    )

    useEffect(() => {
        if (row && Object.keys(row).length > 0) {
            setSelectedCurrency(
                row
                    ? [
                        row.transactionDetails
                            ? row.currency
                            : row.currencyShortName,
                    ]
                    : [],
            )
        }

        if (accountDetails && open) {
            setIsChoosenAccount(true)
            setIsNewAccountComponent(true)
            setFormData(accountDetails)
            setSelectedCurrency(
                accountDetails.currency ? [accountDetails.currency] : [],
            )
            setAdditionalFields({ bankCountry: accountDetails.bankCountry ?? false, beneficiaryCountry: accountDetails.beneficiaryCountry ?? false, beneficiaryName: accountDetails.beneficiaryName ?? false })
        }
        if (open) {
            fetchCountries();
        }
    }, [row, open, accountDetails])

    const fetchCountries = async () => {
        try {
            const countries = await getCountries();
            setCountries(countries?.allCountries)
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    useEffect(() => {
        setIsNewAccountComponent(savedAccounts && savedAccounts.length < 1)
    }, [savedAccounts])

    function onChangeInput(e) {
        const name = e.target.name
        let value
        if (e.target.type === 'checkbox') value = e.target.checked
        else value = e.target.value
        setFormData((x) => {
            return {
                ...x,
                [name]: value,
            }
        })
        if (name == 'amount') {
            setAmountError(
                validateAmount(
                    value,
                    clientWithdrawalFee,
                    selectedCurrency,
                    balancesData,
                ),
            )
        }
        if (
            (name === 'accountNickName' && value && errorInputNames.includes('accountNickName')) ||
            (name === 'beneficiaryName' && value && errorInputNames.includes('beneficiaryName'))
        ) {
            setErrorsInputNames((prev) =>
                prev.filter((x) => x !== name)
            );
        }
    }

    useEffect(() => {
        if (selectedCurrency.length > 0 && formData.amount) {
            setAmountError(
                validateAmount(
                    formData.amount,
                    clientWithdrawalFee,
                    selectedCurrency,
                    balancesData,
                ),
            )
        }
    }, [selectedCurrency])

    function openIsNewAccountComponent() {
        setErrorsInputNames([])
        setIsNewAccountComponent(true)
        setSavedAccountSearchValue();
        setSavedFilterAccounts([]);
        setFormData(clearAllValuesInObj(formData))
        setSelectedCurrency(
            row.currencyShortName || row.currency
                ? [row.currencyShortName || row.currency]
                : [],
        )
    }

    function closeIsNewAccountComponent() {
        setIsNewAccountComponent(false)
        setErrorsInputNames([])
        setAmountError('')
        setCurrencyError("")
    }

    function returnIsChoosenAccount() {
        setIsChoosenAccount(false)
        setIsNewAccountComponent(false)
        setErrorsInputNames([])
        setAmountError('')
        setCurrencyError("")
    }

    function handleIsEditAccount(id) {
        setIsNewAccountComponent(false)
        const account = savedAccounts && savedAccounts.find((x) => x._id == id)
        setFormData((x) => {
            return {
                ...account,
            }
        })
        setSelectedCurrency([account.currency])
        setIsEditAccount(true)
    }

    function hideIsEditAccount(isClearForm = true) {
        setIsEditAccount(false)
        returnIsChoosenAccount()
        isClearForm && setFormData(clearAllValuesInObj(formData))
    }

    function chooseAccount(id) {
        const account = savedAccounts && savedAccounts.find((x) => x._id == id)
        setAdditionalFields({ bankCountry: account.bankCountry ?? false, beneficiaryCountry: account.beneficiaryCountry ?? false, beneficiaryName: account.beneficiaryName ?? false })
        setSelectedCurrency(
            row.currencyShortName || row.currency
                ? [row.currencyShortName || row.currency]
                : account.currency
                    && unfreezeBalances.length > 0
                    && unfreezeBalances.some(balance => balance.currencyShortName === account.currency)
                    ? [account.currency]
                    : [unfreezeBalances[0]?.currencyShortName] || [],
        )
        setFormData((x) => {
            return {
                ...account,
            }
        })
        setIsChoosenAccount(true)
        setIsNewAccountComponent(true)
    }

    function validateFields() {
        let updatedErrors = [];
        if (formData.saveBankAccount) {
            if (!formData.accountNickName || formData.accountNickName.trim() === '') {
                updatedErrors.push('accountNickName');
            }
        }
        if (isEditAccount) {
            if (!formData.accountNickName || formData.accountNickName.trim() === '') {
                updatedErrors.push('accountNickName');
            }
        }
        if (!formData.beneficiaryName || formData.beneficiaryName.trim() === '') {
            updatedErrors.push('beneficiaryName');
        }
        if (!formData.bankCountry) {
            updatedErrors.push('bankCountry');
        }
        if (!formData.beneficiaryCountry) {
            updatedErrors.push('beneficiaryCountry');
        }
        if (validateCurrency(
            selectedCurrency,
            balancesData,
        )) {
            updatedErrors = [...updatedErrors, 'currency']
            setCurrencyError(
                validateCurrency(
                    selectedCurrency,
                    balancesData,
                )
            )
        }

        const amountError = validateAmount(formData.amount, clientWithdrawalFee, selectedCurrency, balancesData);
        if (amountError !== '') {
            updatedErrors.push('amount');
            setAmountError(amountError);
        }
        setErrorsInputNames(updatedErrors);
        return updatedErrors;
    }


    async function submit() {
        setIsLoading(true)
        setFormData({ ...formData, currency: selectedCurrency[0] })
        try {
            if (attachments.length > 0) {
                await Promise.all(
                    attachments.map(async (data, index) => {
                        const transactionImage = `transactionImage${Math.floor(100000 + Math.random() * 900000)}`
                        await getSignedUrl(data, transactionImage, index)
                    }),
                )
            } else {
                createTransactionCall()
            }

        } catch (err) {
            setIsLoading(false)
            setIsFailed(true)
        }
    }

    function onCloseHandler() {
        setIsChoosenAccount(false)
        setIsDelete(false)
        setIsConfirm(false)
        onClose()
        setSavedAccountSearchValue();
        setSavedFilterAccounts([]);
    }

    function onSuccess() {
        setIsLoading(false)
        onCloseHandler()
        dispatch(setSuccessMessage('Withdraw has been completed successfully'))
        setSuccessModal(true)
    }

    function reset() {
        setIsLoading(false)
        resetUseStates()
    }

    function resetUseStates() {
        setIsNewAccountComponent(savedAccounts && savedAccounts.length < 1)
        setSelectedCurrency([])
        setIsEditAccount(false)
        setIsFailed(false)
        setAmountError('')
        setAttachments([])
        setAttachmentsError('')
        setFormData(clearAllValuesInObj(formData))
        setFailedErr('')
        setErrorsInputNames([])
        setAttachmentUrls([])
        setCurrencyError("")
    }

    function onSuccessDelete() {
        if (savedAccounts && savedAccounts.length == 1) {
            setIsNewAccountComponent(true)
        }
        getSavedAccounts()
        setFormData(clearAllValuesInObj(formData))
        setIsEditAccount(false)
        setIsDelete(false)
    }

    function handleFile(files) {
        setAttachmentsError('')
        for (let i = 0; i < files.length; i++) {
            if (files[i].size <= 5 * 1024 * 1024) {
                setAttachments((x) => [...x, files[i]])
            } else {
                setAttachmentsError('File size exceeds the limit (5 MB)')
                setTimeout(() => {
                    setAttachmentsError('')
                }, 2000)
            }
        }
    }

    function handleDelete(e, i) {
        setAttachments(attachments.filter((item, index) => index !== i))
        setAttachmentsError('')
    }

    const handleChange = function (e) {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files)
        }
    }

    const handleToCurrency = (val) => {
        setSelectedCurrency(val)
        setFormData({ ...formData, currency: val[0] })
        setCurrencyError(
            validateCurrency(
                val,
                balancesData,
            ),
        )
        if (
            validateCurrency(val, balancesData) ===
            ''
        ) {
            setErrorsInputNames((prev) =>
                prev.filter((x) => x !== 'currency')
            )
        }
    }

    useEffect(() => {
        if (
            attachments.length > 0 &&
            attachments.length === attachmentUrls.length
        ) {
            createTransactionCall()
        }
    }, [attachmentUrls])

    const getSignedUrl = async (file, transactionImage, filecount) => {
        const payload = {
            imgcount: `file${filecount}`,
            imageType: 'clientPayout',
            transactionImage: transactionImage ? transactionImage : null,
        }
        const index = parseInt(filecount)
        try {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-client-presigned-url',
                {
                    ...payload,
                },
                async (resp) => {
                    if (resp) {
                        await fetch(resp.data[0], {
                            method: 'PUT',
                            body: file,
                            headers: {
                                'Content-Type': 'application/*',
                                'cache-control': 'public, max-age=0',
                            },
                        })
                            .then(async (res) => {
                                const imgUrl = `${imageBaseUrl}/client-payout/file${index}/${transactionImage}/documents`
                                setAttachmentUrls((x) => [...x, imgUrl])
                            })
                            .catch((err) => {
                                setIsLoading(false)
                                setAttachmentsError(
                                    'Something went wrong with uploaded files',
                                )
                                console.log('err: ', err)
                                setAttachmentUrls([])
                            })
                    }
                },
            )
        } catch (err) {
            console.log(err)
            setAttachmentsError('Something went wrong with uploaded files')
            setIsLoading(false)
        }
    }

    function createTransactionCall() {
        const transactionEmail = localStorage.getItem('user_name')
        const data = {
            beneficiaryName: formData.beneficiaryName,
            beneficiaryAddress: formData.beneficiaryAddress,
            bankName: formData.bankName,
            bankAddress: formData.bankAddress,
            beneficiaryAccountNumber: formData.beneficiaryAccountNumber,
            sortCode: formData.sortCode,
            swift: formData.swift,
            iban: formData.iban,
            additionalBankDetails: formData.additionalBankDetails,
            amount: formData.amount,
            reference: formData.reference,
            currency: selectedCurrency[0],
            bankCountry: formData.bankCountry,
            beneficiaryCountry: formData.beneficiaryCountry,
        }
        apiService(
            getClientTransactionUrl(api_url),
            {
                currencyType: 'fiat',
                type: 'Withdrawal',
                transactionDetails:
                    attachmentUrls.length > 0
                        ? { ...data, attachment: attachmentUrls }
                        : data,
                client: clientData.clientId,
                currency: selectedCurrency[0],
                transactionEmail,
                balance: {
                    balanceAmount: formData.amount,
                },
            },
            async (data) => {
                if (data) {
                    // sendNotification(data?.transactionId,data?.paymentProvider)             
                    setTransactionIdFore2e(data.transactionId)
                    onSuccess()
                    setIsConfirm(false)
                    if (formData.saveBankAccount) {
                        saveAccountApi(
                            { ...formData, currency: selectedCurrency },
                            onSaveAccountSuccess,
                            onSaveAccountError,
                            setFailedErr,
                        )
                    }
                    getBalances()
                    getTransactions()
                    if (!formData.saveBankAccount && (!additionalFields.bankCountry || !additionalFields.beneficiaryCountry || !additionalFields.beneficiaryName)) {
                        updateAccount()
                    }
                }
            },
            (err) => {
                setIsLoading(false)
                setIsFailed(true)
                setIsConfirm(false)
                setFailedErr(err)
                console.log(err)
                dispatch(setErrorMessage(err))
            },
        )
    }

    function onSaveAccountSuccess() {
        getSavedAccounts()
    }

    const setIsConfirmHandler = () => {
        const error = validateFields()
        if (error.length === 0) {
            setIsConfirm(true)
        }
    }

    function onSaveAccountError() {
        setIsLoading(false)
        setIsFailed(true)
    }
    function onInputChange(event) {
        const searchVal = event.target.value;
        setSavedAccountSearchValue(searchVal);
        const filterBySearch = savedAccounts.filter((item) => item.accountNickName.toLowerCase()
            .includes(searchVal.toLowerCase())
        )
        setSavedFilterAccounts(filterBySearch);
    }

    function DialogContentComponent() {
        return (
            <div>
                {isConfirm && (
                    <WithdrawConfirm
                        declineConfirm={declineConfirm}
                        submit={submit}
                        formData={formData}
                        selectedCurrency={selectedCurrency}
                        isLoading={isLoading}
                        attachments={attachments}
                    />
                )}
                {row &&
                    !isConfirm &&
                    savedAccounts && savedAccounts.length > 0 &&
                    !isEditAccount &&
                    !isChoosenAccount && (
                        <div className="savedAccounts">
                            <div className="savedAccounts-tabs">
                                <button
                                    className={`${isNewAccountComponent && 'active'}`}
                                    onClick={openIsNewAccountComponent}
                                    data-e2e="new-account"
                                >
                                    New Account
                                </button>
                                <button
                                    className={`${!isNewAccountComponent && 'active'}`}
                                    onClick={closeIsNewAccountComponent}
                                    data-e2e="saved-accounts"
                                >
                                    Saved Accounts
                                </button>
                            </div>
                        </div>
                    )}
                {!isNewAccountComponent && <div className='flex relative items-center mb-3'> <input className='jncInput shadow-none savedSearchInput text-base' type='text' placeholder='search' ref={inputRef}
                    onChange={onInputChange}
                />
                    <SearchOutlined onClick={() => inputRef.current?.focus()} className='absolute right-4 text-[#5c626b]' /></div>}
                {row &&
                    !isConfirm &&
                    !isNewAccountComponent &&
                    !isEditAccount &&
                    !isChoosenAccount && (
                        <SavedAccountsList
                            chooseAccount={chooseAccount}
                            setIsAccountEdit={handleIsEditAccount}
                            savedAccounts={savedAccountSearchValue ? savedFilterAccounts : savedAccounts}
                        />
                    )}
                {row && !isConfirm && isEditAccount &&
                    <>
                        <JncCurrenciesTypeahead
                            selected={selectedCurrency && selectedCurrency[0] ? selectedCurrency : []}
                            onChange={handleToCurrency}
                            hasError={errorInputNames.includes('currency')}
                        />
                        <FieldsForEdit
                            isEditAccount={isEditAccount}
                            countries={countries}
                            formData={formData}
                            setFormData={setFormData}
                            onChangeInput={onChangeInput}
                            setErrorsInputNames={setErrorsInputNames}
                            errorInputNames={errorInputNames}
                        />

                    </>}
                {row &&
                    !isConfirm &&
                    isNewAccountComponent &&
                    !isEditAccount && (
                        <div>
                            <JncCurrenciesTypeahead
                                selected={selectedCurrency && selectedCurrency[0] ? selectedCurrency : []}
                                onChange={handleToCurrency}
                                hasError={!!currencyError}
                                error={currencyError}
                            />

                            {!isChoosenAccount && (
                                <FieldsForEdit
                                    errorInputNames={errorInputNames}
                                    formData={formData}
                                    countries={countries}
                                    setErrorsInputNames={setErrorsInputNames}
                                    setFormData={setFormData}
                                    onChangeInput={onChangeInput}
                                />
                            )}
                            <div className="jncModal__field mt">
                                <p className="jncModal__label">
                                    Amount <span className="required">*</span>
                                </p>
                                <div
                                    className={`jn-client-prefix-input ${amountError ? 'has-error' : ''}`}
                                >
                                    <span>
                                        {getCurrencySymbol(
                                            selectedCurrency[0],
                                        ) || '?'}
                                    </span>
                                    <input
                                        data-e2e="amount-box"
                                        required
                                        onChange={(e) => onChangeInput(e)}
                                        value={formData.amount || ''}
                                        name="amount"
                                        className="theme-modal-field-input"
                                        type="text"
                                        placeholder="Enter Amount"
                                    />
                                </div>
                                {amountError && (
                                    <div
                                        className="jncAlert alert alert-danger"
                                        role="alert"
                                        data-e2e="error"
                                        data-e2e-msg={amountError}
                                    >
                                        {amountError}
                                    </div>
                                )}
                            </div>
                            {
                                !additionalFields.beneficiaryName && isChoosenAccount &&
                                <div className="jncModal__field mt">
                                    <p className="jncModal__label">Beneficiary name  <span className="required">*</span></p>
                                    <input
                                        data-e2e="Beneficiary name"
                                        onChange={(e) => {
                                            if (errorInputNames.includes('beneficiaryName')) {
                                                setErrorsInputNames((prev) =>
                                                    prev.filter((x) => x !== "beneficiaryName")
                                                );
                                            }
                                            setFormData((x) => {
                                                return {
                                                    ...x,
                                                    beneficiaryName: e.target.value,
                                                }
                                            })
                                        }}
                                        value={formData.beneficiaryName}
                                        name="beneficiaryName"
                                        className={`jncInput`}
                                        type="text"
                                        placeholder="Enter Beneficiary name"
                                    />
                                    {errorInputNames.includes("beneficiaryName") && <div className="jncAlert alert alert-danger" role="alert">* Beneficiary Name field is required</div>}
                                </div>
                            }
                            {
                                !additionalFields.bankCountry && isChoosenAccount &&
                                <div className="jncModal__field mt">
                                    <p className="jncModal__label">
                                        Bank Country <span className="required">*</span>
                                    </p>
                                    <Typeahead
                                        id="basic-typeahead-multiple-currency-type"
                                        name="bankCountry"
                                        onChange={(e) => {
                                            if (errorInputNames.includes('bankCountry')) {
                                                setErrorsInputNames((prev) =>
                                                    prev.filter((x) => x !== "bankCountry")
                                                );
                                            }
                                            setFormData((x) => {
                                                return {
                                                    ...x,
                                                    bankCountry: e?.[0]?.name,
                                                }
                                            })
                                        }}
                                        labelKey={(option) => option.name}
                                        options={countries}
                                        placeholder="Select a country"
                                        selected={
                                            countries.filter((country) => country.name === formData.bankCountry)
                                        }
                                        clearButton={true}
                                    />
                                    {errorInputNames.includes("bankCountry") && <div className="jncAlert alert alert-danger" role="alert">* Bank Country field is required</div>}
                                </div>
                            }
                            {
                                !additionalFields.beneficiaryCountry && isChoosenAccount &&
                                <div className="jncModal__field mt">
                                    <p className="jncModal__label">
                                        Beneficiary Country <span className="required">*</span>
                                    </p>
                                    <Typeahead
                                        id="basic-typeahead-multiple-currency-type"
                                        name="beneficiaryCountry"
                                        labelKey={(option) => option.name}
                                        options={countries}
                                        selected={
                                            countries.filter((country) => country.name === formData.beneficiaryCountry)
                                        }
                                        placeholder="Select a country"
                                        clearButton={true}
                                        onChange={(e) => {
                                            if (errorInputNames.includes('beneficiaryCountry')) {
                                                setErrorsInputNames((prev) =>
                                                    prev.filter((x) => x !== "beneficiaryCountry")
                                                );
                                            }
                                            setFormData((x) => {
                                                return {
                                                    ...x,
                                                    beneficiaryCountry: e?.[0]?.name,
                                                }
                                            })
                                        }}
                                    />
                                    {errorInputNames.includes("beneficiaryCountry") && <div className="jncAlert alert alert-danger" role="alert">* Beneficiary country field is required</div>}
                                </div>
                            }
                            <div className="jncModal__field mt">
                                <p className="jncModal__label">Reference</p>
                                <input
                                    data-e2e="reference-textbox"
                                    onChange={(e) => onChangeInput(e)}
                                    value={formData.reference}
                                    name="reference"
                                    className="jncInput"
                                    type="text"
                                    placeholder="Enter Reference (optional)"
                                />
                            </div>
                            <AttachmentsUpload
                                handleFile={handleFile}
                                attachmentsError={attachmentsError}
                                attachments={attachments}
                                handleDelete={handleDelete}
                                handleChange={handleChange}
                            />
                        </div>
                    )}
            </div>
        )
    }

    function DialogActionsComponent() {
        return (
            <div className="w-100">
                {row &&
                    !isConfirm &&
                    isNewAccountComponent &&
                    !isEditAccount &&
                    !isChoosenAccount && (
                        <div
                            className={`jncModal__btns justify-end`}
                        >
                            {savedAccounts.length > 0 && (
                                <div className="min-992">
                                    <JncButton
                                        disabled={isLoading}
                                        onClickCall={closeIsNewAccountComponent}
                                        text="Return"
                                        isOutlinedStyle={true}
                                    />
                                </div>
                            )}
                            <JncButton
                                loading={isLoading}
                                disabled={amountError || currencyError}
                                onClickCall={setIsConfirmHandler}
                                dataE2e="submit-btn"
                                text="Continue"
                                isFullOnMobile={true}
                            />
                        </div>
                    )}
                {isChoosenAccount && !isConfirm && (
                    <div className="grid-on-mobile jncModal__btns justify-end">
                        <JncButton
                            disabled={isLoading}
                            text="Return"
                            isOutlinedStyle={true}
                            onClickCall={returnIsChoosenAccount}
                        />
                        <JncButton
                            loading={isLoading}
                            disabled={amountError || currencyError}
                            onClickCall={setIsConfirmHandler}
                            dataE2e="submit"
                            text="Continue"
                        />
                    </div>
                )}
                {row && !isConfirm && isEditAccount && (
                    <div className="savedAccounts-btns-right">
                        <div className="min-516">
                            <JncButton
                                disabled={isLoading}
                                onClickCall={hideIsEditAccount}
                                text="Cancel"
                                isOutlinedStyle={true}
                            />
                        </div>
                        <JncButton
                            loading={isLoading}
                            onClickCall={updateAccount}
                            text="Save"
                        />
                    </div>
                )}
                {row &&
                    !isConfirm &&
                    !isNewAccountComponent &&
                    !isEditAccount && (
                        <div className="jncModal__btns justify-end">
                            <JncButton
                                onClickCall={onCloseHandler}
                                text="Close"
                                isOutlinedStyle={true}
                            />
                        </div>
                    )}
                {isConfirm && (
                    <div className="jncModal__btns grid-on-mobile justify-end no-border">
                        <JncButton
                            disabled={isLoading}
                            text="Cancel"
                            isOutlinedStyle={true}
                            onClickCall={declineConfirm}
                        />
                        <JncButton
                            text="Confirm"
                            loading={isLoading}
                            onClickCall={submit}
                            dataE2e="confirm-btn"
                        />
                    </div>
                )}
            </div>
        )
    }

    function onUpdateAccountSuccess(isClearForm = true) {
        setIsLoading(false)
        getSavedAccounts()
        hideIsEditAccount(isClearForm)
    }

    function onUpdateAccountError() {
        setIsLoading(false)
    }

    const declineConfirm = () => {
        setIsConfirm(false)
    }

    function updateAccount() {
        validateFields()
        if (!formData.accountNickName?.trim() || !formData.beneficiaryName?.trim() || !formData.bankCountry || !formData.beneficiaryCountry || !selectedCurrency.length) return
        setIsLoading(true)
        updateAccountApi(
            { ...formData, currency: selectedCurrency },
            onUpdateAccountSuccess,
            onUpdateAccountError,
            setFailedErr,
        )
    }

    const backToAccount = () => setIsConfirm(false)

    useEffect(() => {
        if (open) {
            fetchBalances()
        }
    }, [open])


    const fetchBalances = async () => {
        await getBalances()
    }
    const handleSuccessModal = () => {
        setSuccessModal(false)
        resetUseStates()
    };

    return (
        <div>
            <JunoClientActionModal
                onClose={onCloseHandler}
                open={open}
                titles={{
                    start: 'Withdrawal',
                    edit: 'Edit Accounts',
                    confirm: 'Withdrawal Confirmation',
                }}
                backTo={{ start: hideIsEditAccount, account: backToAccount }}
                states={{
                    start: !isNewAccountComponent && !isEditAccount,
                    edit: isEditAccount && !isConfirm,
                    chosen:
                        isChoosenAccount && isNewAccountComponent && !isConfirm,
                    new:
                        isNewAccountComponent &&
                        !isEditAccount &&
                        !isChoosenAccount &&
                        !isConfirm,
                    confirm: isConfirm,
                }}
                isLoading={isLoading}
                isFailed={isFailed}
                reset={reset}
                failedErr={failedErr}
                formData={formData}
                isDelete={isDelete}
                setIsDelete={setIsDelete}
                onSuccessDelete={onSuccessDelete}
                transactionIdFore2e={transactionIdFore2e}
                dialogContent={DialogContentComponent()}
                dialogActions={DialogActionsComponent()}
            />
            <JncSuccessModal
                open={successModal}
                onClose={handleSuccessModal}
                amount={formData.amount}
                currency={formData?.currency}
                title="Successful!"
                subtitle="Has been withdrawn successfully!"
            />
        </div>
    )
}
