import React from 'react'

const Stepper = ({ currentStep, steps }) => {
    return (
        <div className='w-full flex flex-col items-center'>
            <div className='flex w-full lg:max-w-[600px] justify-center'>
                <div style={{ width: `${(100 / steps.length) * (currentStep)}%` }} className=' bg-white h-[3px] relative'>
                    <span className='w-2 h-2 rounded-full bg-white absolute -top-0.5 right-0'></span>
                </div>
                <div className='flex-1 bg-[#51525C] h-[3px] '></div>
            </div>
            <div className='hidden lg:flex justify-around mt-2 w-full max-w-[600px]'>
                {steps.map((step, index) => <span key={step} className={`${index + 1 === currentStep ? "text-white  " : "!text-[#A0A0AB]"} text-white text-xs font-inter font-medium`}>{step}</span>)}
            </div>


        </div>
    )
}

export default Stepper