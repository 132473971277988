import React, { useEffect, useState } from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import { useSelector } from 'react-redux'
import { JncCustomSelect } from '../../../../components/global/JncCustomSelect'
import CopyButton from '../../../../components/global/CopyButton'
import { apiService } from '../../../../../../common/apiCallService'
import qrcode from 'qrcode'
import { useJunoCommonFunctions } from '../../../../../helpers'
import { JncFailedModal } from '../../../../components/modals/JncFailedModal'


export const BalanceCryptoReceive = (props) => {
    const { onClose, open } = props
    const { getCryptoBalances } = useJunoCommonFunctions()
    const { balancesCrypto, unfreezeCryptoCurrencies } = useSelector((state) => state.juno)
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno?.clientData)
    const [selectedCrypto, setSelectedCrypto] = useState(null)
    const [selectedCryptoAddress, setSelectedCryptoAddress] = useState(null)
    const [imageData, setImageData] = useState(null)
    const [blockchain, setBlockchain] = useState(null)
    const [warning, setWarning] = useState(false)
    const [failedModal, setFailedModal] = useState({ open: false, msg: "" })


    useEffect(() => {
        if (!selectedCrypto) {
            unfreezeCryptoCurrencies &&
                setSelectedCrypto(unfreezeCryptoCurrencies[0]?.currencyShortName)
        }
    }, [unfreezeCryptoCurrencies])

    useEffect(() => {
        if (!selectedCrypto) return;
        const selectedCryptoData = balancesCrypto.find(
            crypto => crypto.currencyShortName === selectedCrypto
        );
        setBlockchain(selectedCryptoData?.blockchain || null);
        const warningCryptos = ['DAI', 'USDT', 'USDC'];
        setWarning(warningCryptos.includes(selectedCrypto));
    }, [selectedCrypto, balancesCrypto]);

    useEffect(() => {
        if (balancesCrypto && open) {
            
            const selectedWallet = balancesCrypto.find(crypto => crypto.currencyShortName === selectedCrypto);
            const walletAddress = selectedWallet?.walletAddress || balancesCrypto.find(crypto => crypto.blockchain === selectedWallet.blockchain && crypto?.walletAddress?.key)?.walletAddress
           
            if (walletAddress) {
                setSelectedCryptoAddress(walletAddress)
                generateQRcode(walletAddress)
            } else {
                setSelectedCryptoAddress(null)
            }
        }
    }, [balancesCrypto, open, selectedCrypto])

    const generateQRcode = async (address) => {
        await qrcode.toDataURL(address?.key, function (err, imgData) {
            if (err) {
                console.error(err)
                alert('Something went wrong!')
            } else if (imgData) {
                setImageData(imgData)
            }
        })
    }

    const updateCryptoAddress = () => {
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/update-wallet-address',
            {
                clientId: clientData?.clientId,
                cryptoId: selectedCrypto,
                blockchain,
                accountNumber: clientData?.accountNumber,
            },
            async (data) => {
                if (data) {
                    getCryptoBalances()
                }
            },
            (err) => {
                console.error(err)
                setFailedModal({ open: true, msg: err })
            },
        )
    }

    const dialogContentComponent = () => {
        return (
            <>
                <div className={`flex items-center ${!warning && "flex-col"} gap-[30px]`}>
                    <img
                        src={imageData}
                        alt=""
                        className="w-[160px] h-[160px] p-2 rounded-2xl border"
                    />
                    <div className="">
                        <h1 className="text-base font-semibold text-[#18181B]">
                            Only send {selectedCrypto} to this address
                        </h1>
                        {
                            warning &&
                            <>
                                <p className='text-sm font-normal text-[#18181B] pt-1'>This address can only receive <span className='font-semibold'>{selectedCrypto}</span> on the {blockchain} network. Don't send <span className='font-semibold'>{selectedCrypto}</span> on any other network or it may be lost.</p>
                                <p className='text-sm font-normal text-[#18181B] pt-1'> Receiving crypto over the wrong network could mean losing your funds. Be sure to choose the right network. When in doubt, send a small amount first.</p>
                            </>
                        }
                    </div>
                </div>
                <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                    <h1 className="text-base font-normal text-[#26272B] col-span-1">
                        Recipient wallet:
                    </h1>
                    <div className="col-span-2">
                        <JncCustomSelect
                            value={selectedCrypto}
                            onChange={(e) => setSelectedCrypto(e)}
                            options={unfreezeCryptoCurrencies || []}
                            className="w-full !rounded-l-3xl max-sm:!w-full"
                            showTether={false}
                        />
                    </div>
                </div>
                <div className="border rounded-lg p-4 mt-[40px] w-full">
                    <h1 className="text-base font-light text-[#51525C] ">
                        Receiving address:
                    </h1>
                    {
                        !selectedCryptoAddress ?
                            <div className="">
                                <p className='text-[#D92D20] text-base font-medium mt-1'>
                                    Not generated
                                </p>
                                <button
                                    onClick={updateCryptoAddress}
                                    data-e2e="generate-btn"
                                    className='w-full border border-[#D1D1D6] text-base font-medium rounded-full py-[11px] mt-6'>
                                    Generate
                                </button>
                            </div>
                            :
                            <p className="grid grid-cols-4 ">
                                <div className="col-span-3 truncate">
                                    {selectedCryptoAddress?.key}
                                </div>
                                <div className="col-span-1 justify-self-end">
                                    <CopyButton copyValue={selectedCryptoAddress?.key} />
                                </div>
                            </p>
                    }
                </div>
            </>
        )
    }

    const handleFailedModal = () => {
        setFailedModal({ open: false, msg: "" })
        onClose();
    }

    return (
        <>
            <JunoClientActionModal
                onClose={onClose}
                titles={{ start: 'Receive Crypto' }}
                states={{
                    start: true,
                    confirm: false,
                }}
                open={open}
                dialogContent={dialogContentComponent()}
            />
            <JncFailedModal
                open={failedModal.open}
                onClose={handleFailedModal}
                error={"Error while generating wallet, Please try again in sometime."}
                title="Receive Failed!"
            />
        </>
    )
}
