import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import * as actions from '../../../../Redux/actions'
import { apiService } from '../../../../common/apiCallService'
import Icon from '../../../client/icons/Icon'
import JncButton from '../../../client/components/global/JncButton'
import PasswordField from '../../../client/components/global/JncPasswordField'

const JunoCreatePassword = ({ form, onBack, setRegistrationData, onClick }) => {
    const dispatch = useDispatch()
    const api_url = useSelector((state) => state.config.api_url)
    const [loading, setLoading] = useState(false)

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordVisibility, setPasswordVisibility] = useState({
        password: false,
        confirmPassword: false,
    })
    const [errors, setErrors] = useState({
        // length: false,
        // symbol: false,
        // upperOrNumber: false,
        match: false,
    })
    const [alertMsg, setAlertMsg] = useState(null)

    const validatePasswordMatch = (password, confirmPassword) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            match: password !== confirmPassword
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "password") {
            setPassword(value);
        } else if (name === "confirmPassword") {
            setConfirmPassword(value);
            validatePasswordMatch(password, value);
        }
    };

    const toggleVisibility = (field) => {
        setPasswordVisibility((prev) => ({
            ...prev,
            [field]: !prev[field],
        }))
    }

    const onSubmit = () => {
        setLoading(true);
        if (!password || !confirmPassword) {
            setAlertMsg('Password and Confirm Password cannot be empty.');
            setLoading(false);
            return;
        }

        if (errors.match) {
            setAlertMsg('The passwords do not match.');
            setLoading(false);
            return;
        }

        dispatch(actions.setLoading(true));

        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
            '/restapi/create-password',
            {
                clientEmail: form?.clientEmail || form?.businessEmail,
                password: password,
            },
            async (data) => {
                setAlertMsg(null)
                setLoading(false)
                setRegistrationData(data)
                localStorage.setItem('user_name', data.clientEmail)
                onClick()
            },
            (err) => {
                setAlertMsg(err)
                setLoading(false)
                console.log(err)
            }
        )

        setAlertMsg(null)
    }

    return (
        <div className="md:w-[460px] w-full h-full md:h-fit rounded-lg bg-white">
            <div className="md:flex hidden items-center gap-3 py-[22px] px-[14px] border-b-[1px] border-[#CDD5DF]">
                <span onClick={onBack} className="self-center cursor-pointer">
                    <Icon id="arrowLeft" />
                </span>
                <p className="text-[#18181B] font-semibold text-2xl ">
                    Create a password
                </p>
            </div>

            <form className="flex flex-col gap-8 px-4 py-[22px]">
                {alertMsg && (
                    <div className="alert alert-danger" role="alert">
                        {alertMsg}
                    </div>
                )}
                <div className="flex flex-col gap-6">
                    <PasswordField
                        label="Enter password"
                        name="password"
                        value={password}
                        showPassword={passwordVisibility.password}
                        onChange={handleInputChange}
                        toggleShowPassword={() => toggleVisibility('password')}
                        placeholder="Enter your password"
                        error={errors.match}
                    />
                    <PasswordField
                        label="Confirm password"
                        name="confirmPassword"
                        value={confirmPassword}
                        showPassword={passwordVisibility.confirmPassword}
                        onChange={handleInputChange}
                        toggleShowPassword={() =>
                            toggleVisibility('confirmPassword')
                        }
                        placeholder="Confirm your password"
                        error={errors.match}
                    />
                </div>
                {errors.match && (
                    <Typography
                        variant="body2"
                        color="error"
                    >
                        * Password does not match
                    </Typography>
                )}
                <JncButton
                    text="Continue"
                    className="w-full"
                    disabled={
                        !password ||
                        !confirmPassword ||
                        Object.values(errors).some((err) => err)
                    }
                    onClickCall={onSubmit}
                    loading={loading}
                />
            </form>
        </div>
    )
}

export default JunoCreatePassword
