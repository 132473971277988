import Typography from '@mui/material/Typography'
import { Checkbox, FormControl, ListItemText, MenuItem, Select } from '@mui/material'

export default function RegisterSelectField({
    label,
    required = false,
    value,
    onChange,
    placeholder = "Select an option",
    options = [],
    sx = {},
    isMultiSelect = false,
    name = "",
    error
}) {
    return (
        <div className="jncAuth__field mb-[12px] px-4">
            {label && (
                <p className="jncAuth__field-label">
                    {label}
                    {required && (
                        <Typography
                            component="span"
                            sx={{
                                color: "#3F3F46",
                                fontSize: "1.2rem",
                                lineHeight: 1,
                            }}
                        >
                            *
                        </Typography>
                    )}
                </p>
            )}
            <FormControl fullWidth>
                <Select
                    name={name}
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    multiple={isMultiSelect}
                    renderValue={(selected) => {
                        if (isMultiSelect) {
                            return selected && selected.length > 0
                                ? selected.join(", ")
                                : placeholder;
                        }
                        return selected || placeholder;
                    }}
                    sx={{
                        border: "1px solid #E5E7EB",
                        borderRadius: "8px",
                        padding: "10px",
                        ".MuiSelect-outlined": {
                            padding: "0",
                        },
                        backgroundColor: "white",
                        ...sx,
                    }}
                    MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            overflow: 'auto',
                          },
                        },
                      }}
                >
                    {!isMultiSelect && (
                        <MenuItem value="">
                            <em>{placeholder}</em>
                        </MenuItem>
                    )}
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {isMultiSelect ? (
                                <>
                                    <Checkbox checked={Array.isArray(value) && value.indexOf(option.value) > -1} />
                                    <ListItemText primary={option.label} />
                                </>
                            ) : (
                                option.label
                            )}
                        </MenuItem>
                    ))}
                </Select>
                {error &&
                    <p className="text-red-500 text-sm ">
                        *{error}
                    </p>}
            </FormControl>
        </div>
    );
};