import React, { useState } from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import HelpIcon from '@mui/icons-material/Help'
import JncButton from '../../../client/components/global/JncButton'
import { useJunoCommonFunctions } from '../../../helpers'
import { apiService } from '../../../../common/apiCallService'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { documentsFeilds } from '../../../client/constant'

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))

const BusinesssDocumentation = ({ handleStep, handleSaveClick, businessData, getClientBusinessData, form }) => {
    const [attachments, setAttachments] = useState([]);
    const [businessAttachments, setBusinessAttachments] = useState([]);
    const [attachmentsError, setAttachmentsError] = useState('');
    const { useWindowWidth, handleRegistrationStages } = useJunoCommonFunctions();
    const apiUrl = useSelector((state) => state.config.api_url);
    const imageBaseUrl = useSelector((state) => state.config.image_base_url);
    const [loading, setLoading] = useState(false);
    const windowWidth = useWindowWidth();

    useEffect(() => {
        if (businessData) {
            const documentData = documentsFeilds
                .filter(({ key }) => businessData?.documents[key]?.length > 0)
                .map(({ label, key }) => ({
                    label,
                    files: businessData?.documents[key],
                }));
            setBusinessAttachments(documentData);
        }
    }, [businessData]);

    const handleFile = (e, label) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files) return;

        setAttachmentsError('');

        setAttachments((prevAttachments) => {
            const updatedAttachments = prevAttachments.map((attachment) => {
                if (attachment.label === label) {
                    const existingFiles = attachment.files || [];
                    const newFiles = Array.from(files).filter((file) => {
                        if (file.size > 4 * 1024 * 1024) {
                            setAttachmentsError('File size exceeds the limit (4 MB)');
                            setTimeout(() => setAttachmentsError(''), 2000);
                            return false;
                        }

                        if (existingFiles.some((existingFile) => existingFile.name === file.name)) {
                            setAttachmentsError(`File "${file.name}" is already uploaded`);
                            setTimeout(() => setAttachmentsError(''), 5000);
                            return false;
                        }

                        return true;
                    });

                    return {
                        ...attachment,
                        files: [...existingFiles, ...newFiles],
                    };
                }
                return attachment;
            });

            const labelExists = updatedAttachments.some((attachment) => attachment.label === label);
            if (!labelExists) {
                updatedAttachments.push({ label, files: Array.from(files) });
            }

            return updatedAttachments;
        });
    };

    const handleDeleteBusinessDocument = async (label, fileName) => {
        const field = documentsFeilds.find((field) => field.label === label);
        if (!field) return;

        const body = {
            businessEmail: form.businessEmail || localStorage.getItem('user_name'),
            label: field.key,
            fileName,
        };

        try {
            await apiService(
                `${window.location.hostname === 'localhost' ? apiUrl : ''}/restapi/delete-business-document`,
                body,
                () => getClientBusinessData(),
                (err, code) => {
                    // Remove the file from attachments state when API returns 404
                    if (code === 404) {
                        setAttachments(prevAttachments => {
                            return prevAttachments.map(attachment => {
                                if (attachment.label === label) {
                                    return {
                                        ...attachment,
                                        files: attachment.files.filter(file => file.name !== fileName)
                                    };
                                }
                                return attachment;
                            }).filter(attachment => attachment.files.length > 0);
                        });
                    }
                }
            )
        } catch (error) {
            console.error(error);
        }
    };

    const getSignedUrl = async (file, fileName, index) => {
        const payload = {
            imgcount: `file${index}`,
            imageType: 'userDocuments',
            fileName: fileName || 'images',
        };

        try {
            const response = await new Promise((resolve, reject) => {
                apiService(
                    `${window.location.hostname === 'localhost' ? apiUrl : ''}/restapi/get-client-presigned-url`,
                    payload,
                    resolve,
                    reject
                );
            });

            if (!response || !response.data || !response.data[0]) {
                throw new Error('No signed URL received');
            }

            await fetch(response.data[0], {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': 'application/*',
                    'cache-control': 'public, max-age=0',
                },
            });

            return `${imageBaseUrl}/user-documents/file${index}/${fileName}/documents`;
        } catch (err) {
            console.error(err);
            setAttachmentsError('Something went wrong with uploaded files');
            throw err;
        }
    };

    const onContinue = async () => {
        if (attachments.length === 0) return;

        setLoading(true);
        const tempAttachmentUrls = {};

        try {
            for (const attachment of attachments) {
                const documentField = documentsFeilds.find((field) => field.label === attachment.label);
                if (!documentField) {
                    console.warn(`No mapping found for label: ${attachment.label}`);
                    continue;
                }

                const backendKey = documentField.key;
                const urls = [];

                for (const [index, file] of attachment.files.entries()) {
                    const signedUrl = await getSignedUrl(file, file.name, index);
                    urls.push({ name: file.name, attachment: signedUrl });
                }

                tempAttachmentUrls[backendKey] = urls;
            }

            await handleRegistrationStages('upload-documents', { documents: tempAttachmentUrls });
            handleStep();
        } catch (error) {
            console.error('Error:', error);
            setAttachmentsError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const UploadInput = ({ label, toolTipText }) => {
        const attachment = attachments?.find((item) => item?.label === label) || {};
        const businessAttachment = businessAttachments?.find((item) => item?.label === label) || {};

        const currentFiles = [
            ...(attachment?.files || []),
            ...(businessAttachment?.files || [])
        ];

        const handleClick = (e) => {
            e.preventDefault()
            document.getElementById(`file-input-${label}`).click()
        }

        return (
            <div>
                <p className="text-sm font-medium mb-[6px] text-[#3F3F46]">
                    <span>{label} * </span>
                    {toolTipText && (
                        <LightTooltip title={toolTipText} placement="right">
                            <span className="ml-1 cursor-pointer self-center">
                                <HelpIcon
                                    color="#70707B"
                                    sx={{ color: '#70707B', fontSize: 16 }}
                                />
                            </span>
                        </LightTooltip>
                    )}
                </p>
                <div className="rounded-lg border-[1px] border-[#D1D1D6] px-[14px] py-[10px]">
                    <label
                        htmlFor={`file-input-${label}`}
                        className="w-full cursor-pointer"
                    >
                        <div className="flex gap-2 items-center">
                            <input
                                type="file"
                                id={`file-input-${label}`}
                                onChange={(e) => handleFile(e, label)}
                                multiple
                                className="hidden"
                            />
                            <button
                                type="button"
                                className="bg-gray-200 px-2 py-[6px] text-sm border rounded-md border-gray-700"
                                onClick={handleClick}
                            >
                                Choose file
                            </button>
                            <span className="text-sm text-gray-900">
                                {currentFiles?.length > 0
                                    ? `${currentFiles.length} file(s) selected`
                                    : 'No file chosen'}
                            </span>
                        </div>
                    </label>
                </div>
                {currentFiles?.length > 0 && (
                    <div className="my-[12px]">
                        <p className="text-sm font-normal text-[#202939] pb-[6px] border-b-[1px] border-[#CDD5DF]">
                            File/Attachment name
                        </p>
                        {currentFiles.map((file, index) => (
                            <div
                                key={index}
                                className="my-[12px] flex justify-between border-b-[1px] border-[#CDD5DF] pb-[6px]"
                            >
                                <p className="text-[#0E7090] text-sm font-medium">
                                    {file?.name}
                                </p>
                                <span
                                    onClick={() =>
                                        handleDeleteBusinessDocument(
                                            label,
                                            file?.name
                                        )
                                    }
                                >
                                    <DeleteOutlineOutlinedIcon className="cursor-pointer" />
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    };

    return (
        <div className="w-full h- md:w-[500px] bg-white rounded-lg text-black md:max-h-[726px] lg:max-h-[560px]">
            {windowWidth > 768 && (
                <div className="px-[14px] h-[76px] lg:h-[108px] py-[22px] border-b-[1px] justify-between items-center flex">
                    <p className="font-semibold text-2xl">
                        Business <br className="hidden lg:block" />{' '}
                        Documentation
                    </p>
                    <button className="flex items-center justify-center text-sm text-gray-900 bg-gray-200 h-8 rounded-full font-medium px-4 py-[6px]" onClick={handleSaveClick}>
                        {windowWidth < 1100 ? 'Skip' : 'Save & Continue'}
                    </button>
                </div>
            )}
            <div className="p-[22px] md:h-[606px] lg:h-[453px] overflow-y-scroll">
                <p className="bg-[#E3E8EF] text-sm p-[12px] font-medium rounded-md text-[#3F3F46]">
                    Files should be up to 4mb. You can upload one or multiple.
                </p>
                {attachmentsError &&
                    <Typography
                        variant="body2"
                        color={'error'}
                    >
                        {attachmentsError}
                    </Typography>}
                <div className="mt-6 flex flex-col gap-3">
                    {documentsFeilds.map((item) => {
                        return (
                            <UploadInput
                                label={item.label}
                                toolTipText={item?.toolTipText}
                            />
                        )
                    })}
                </div>
                <JncButton
                    text="Continue"
                    disabled={attachmentsError}
                    onClickCall={onContinue}
                    className="w-full mt-4"
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default BusinesssDocumentation
