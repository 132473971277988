import React, { useRef, useState } from "react";
import FocusTrap from "focus-trap-react";
import { usePopper } from "react-popper";
import { DayPicker } from "react-day-picker";
import { format, parse } from 'date-fns'

export default function JunoRegistrationDayPicker({
    form,
    setForm, setError, validateField
}) {
    const popperRef = useRef(null);
    const buttonRef = useRef(null);
    const [popperElement, setPopperElement] = useState(null);
    const popper = usePopper(popperRef.current, popperElement, {
        placement: "bottom-start",
    });
    const [isPopperOpen, setIsPopperOpen] = useState(false);

    const closePopper = () => setIsPopperOpen(false);
    const handleDateClick = () => setIsPopperOpen(true);

    function onDateSelect(date) {
        const formattedDate = format(
            new Date(
                date.toString()
            ),
            'dd/MM/y'
        )
        setForm((prevForm) => ({
            ...prevForm,
            ["dateOfBirth"]: formattedDate,
        }));
        setError((prevError) => ({
            ...prevError,
            dateOfBirth: validateField('dateOfBirth', formattedDate),
        }));
        closePopper()
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
        setError((prevError) => ({
            ...prevError,
            dateOfBirth: validateField('dateOfBirth', value),
        }));
    };

    const selectedDate = form.dateOfBirth
        ? parse(form.dateOfBirth, "dd/MM/yyyy", new Date())
        : undefined;

    return (
        <div ref={popperRef} className="jnc-date-picker-container">
            <input
                className="jnc-date-picker-input"
                placeholder='DD/MM/YYYY'
                name='dateOfBirth'
                onChange={handleInputChange}
                onClick={handleDateClick}
                value={form.dateOfBirth}
            />
            {isPopperOpen && (
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper,
                        fallbackFocus: buttonRef.current,
                    }}
                >
                    <div
                        tabIndex={-1}
                        style={popper.styles.popper}
                        className="jnc-custom-date-picker"
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                        aria-label="DayPicker calendar"
                    >
                        <DayPicker
                            mode="single"
                            captionLayout="dropdown"
                            initialFocus={isPopperOpen}
                            selected={selectedDate}
                            onSelect={onDateSelect}
                            fromYear={1920}
                            toYear={2023}
                            modifiersClassNames={{
                                selected: "jnc-selected-date",
                            }}
                        />
                    </div>
                </FocusTrap>
            )}
        </div>
    );
}