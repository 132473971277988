import * as actionTypes from './actionTypes'

export const setToken = (data) => {
    return {
        type: actionTypes.SET_TOKEN,
        data
    }
}
export const setLoading = (data) => {
    return {
        type: actionTypes.SET_LOADING,
        data
    }
}
export const setShowVerificationModal = (data) => {
    return {
        type: actionTypes.SET_SHOW_VERIFICATION_MODAL,
        data
    }
}