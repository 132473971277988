import React from 'react'
import jsPDF from 'jspdf'
import JncButton from '../components/global/JncButton'
import { format } from 'date-fns'
import { icons } from '../icons/icons'
import Icon from '../icons/Icon'

const JncPaymentConfirmation = ({ data }) => {
    const whiteLabelLogo = sessionStorage.getItem('logo')
    const cryptoLogo = sessionStorage.getItem('cryptoLogo')
    const activeCurrencyPage = localStorage.getItem('activeCurrencyPage')

    const generatePDF = async (paymentData) => {
        const imageUrl =
            activeCurrencyPage === 'fiat' ? whiteLabelLogo : cryptoLogo

        try {
            const response = await fetch(imageUrl)
            if (!response.ok) {
                throw new Error(`Failed to fetch image: ${response.statusText}`)
            }

            const contentType = response.headers.get('content-type')

            let dataUrl
            if (contentType.includes('svg')) {
                const svgText = await response.text()
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                const img = new Image()

                img.onload = () => {
                    canvas.width = img.width
                    canvas.height = img.height
                    ctx.drawImage(img, 0, 0)
                    dataUrl = canvas.toDataURL('image/png')
                    createPaymentConfirmationPDF(
                        paymentData,
                        dataUrl,
                        img.width,
                        img.height
                    )
                }

                img.onerror = () => {
                    console.error('Failed to load SVG image into the canvas')
                }

                img.src = 'data:image/svg+xml;base64,' + btoa(svgText)
            } else {
                const img = new Image()
                img.crossOrigin = 'Anonymous'
                img.src = imageUrl
                img.onload = () => {
                    createPaymentConfirmationPDF(paymentData, img, 50, 10)
                }
            }
        } catch (error) {
            console.error('Error generating PDF:', error)
        }
    }

    const createPaymentConfirmationPDF = (
        paymentData,
        dataUrl,
        imgWidth,
        imgHeight
    ) => {
        const doc = new jsPDF()

        const maxWidth = 50
        const maxHeight = 10

        let width = imgWidth
        let height = imgHeight

        const aspectRatio = width / height
        if (width > maxWidth || height > maxHeight) {
            if (width / maxWidth > height / maxHeight) {
                width = maxWidth
                height = maxWidth / aspectRatio
            } else {
                height = maxHeight
                width = maxHeight * aspectRatio
            }
        }

        const textColor = [52, 64, 84]

        // Add title and date
        doc.setFontSize(15)
        doc.setFont('Inter', 'bold')
        doc.text('Payment confirmation', 10, 10)

        doc.setFontSize(11)
        doc.setFont('Inter', 'normal')
        doc.setTextColor(...textColor)
        doc.text(paymentData.date, 10, 18)

        // Add Transaction ID
        doc.setFont('Inter', 'bold')
        doc.text('Transaction ID', 10, 30)
        doc.setFont('Inter', 'normal')
        doc.setTextColor(...textColor)
        doc.text(paymentData.transactionId, 10, 36)

        // Add paid on behalf of section
        doc.setFont('Inter', 'bold')
        doc.text('Paid on behalf of:', 10, 45)
        doc.setFont('Inter', 'normal')
        doc.setTextColor(...textColor)
        doc.text('Customer name:', 10, 53)
        doc.text(paymentData.customerName, 50, 53)
        doc.text('Customer address:', 10, 61)
        doc.text(paymentData.customerAddress, 50, 61, { maxWidth: 150 })

        // Add Remitter Details section
        doc.setFont('Inter', 'bold')
        doc.text('Beneficiary Details', 10, 75)
        doc.setFont('Inter', 'normal')
        doc.setTextColor(...textColor)
        doc.text('Beneficiary name:', 10, 83)
        doc.text(paymentData.beneficiaryName, 50, 83)
        doc.text('Beneficiary address:', 10, 91)
        doc.text(paymentData.beneficiaryAddress, 50, 91, { maxWidth: 150 })

        // Add Beneficiary Bank Details section
        doc.setFont('Inter', 'bold')
        doc.text('Beneficiary Bank Details', 10, 105)
        doc.setFont('Inter', 'normal')
        doc.setTextColor(...textColor)
        doc.text('Bank name:', 10, 113)
        doc.text(paymentData.beneficiaryBankName, 50, 113)
        doc.text('Bank address:', 10, 121)
        doc.text(paymentData.beneficiaryBankAddress, 50, 121, { maxWidth: 150 })
        doc.text('IBAN:', 10, 129)
        doc.text(paymentData.iban, 50, 129)
        doc.text('SWIFT:', 10, 137)
        doc.text(paymentData.swift, 50, 137)
        doc.text('Account number:', 10, 145)
        doc.text(paymentData.accountNumber, 50, 145)
        doc.text('Sort code:', 10, 153)
        doc.text(paymentData.sortCode, 50, 153)
        doc.text('Additional info:', 10, 161)
        doc.text(paymentData.additionalInfo, 50, 161)

        // Add Payment section
        doc.setFont('Inter', 'bold')
        doc.text('Payment', 10, 175)
        doc.setFont('Inter', 'normal')
        doc.setTextColor(...textColor)
        doc.text('Currency:', 10, 183)
        doc.text(paymentData.currency, 50, 183)
        doc.text('Amount:', 10, 191)
        doc.text(paymentData.amount, 50, 191)

        // Add Reference section
        doc.setFont('Inter', 'bold')
        doc.text('Reference', 10, 205)
        doc.setFont('Inter', 'normal')
        doc.setTextColor(...textColor)
        doc.text(paymentData.reference, 10, 213, { maxWidth: 190 })

        // Add footer
        doc.addImage(dataUrl, 'PNG', 10, 260, width, height)
        doc.setDrawColor(208, 213, 221)
        doc.line(10, 270, 200, 270)
        doc.setFontSize(10)
        doc.setFont('Inter', 'bold')
        doc.setTextColor(29, 41, 57)
        doc.text(`© Junomoney ${new Date().getFullYear()} | All rights reserved`, 10, 275)
        doc.setTextColor(71, 84, 103)
        doc.setFont('Inter', 'normal')
        doc.text(
            `Juno Pay Ltd (trading as Juno Money) - (Company Number: 2024801421), with its registered office at Suite #229, 6030 88ST NW, Edmonton, Alberta, T6E6G4, Canada. Juno Pay Ltd is authorised by FINTRAC as a Money Service Business (MSB No. M23335654).`,
            10,
            280,
            { maxWidth: 190 }
        )

        doc.save('payment-confirmation.pdf')
    }

    const paymentData = {
        date: format(data.status.updated, 'dd.MM.yy hh:mm'),
        transactionId: data.transactionId || '-',
        customerName: data.clientRef.name || '-',
        customerAddress: data.clientRef.address || '-',
        beneficiaryName: data.transactionDetails[0].beneficiaryName || '-',
        beneficiaryAddress:
            data.transactionDetails[0].beneficiaryAddress || '-',
        beneficiaryBankName: data.transactionDetails[0].bankName || '-',
        beneficiaryBankAddress: data.transactionDetails[0].bankAddress || '-',
        iban: data.transactionDetails[0].iban || '-',
        swift: data.transactionDetails[0].swift || '-',
        accountNumber:
            data.transactionDetails[0].beneficiaryAccountNumber || '-',
        sortCode: data.transactionDetails[0].sortCode || '-',
        additionalInfo: data.transactionDetails[0].additionalBankDetails || '-',
        currency: data.transactionDetails[0].currency || '-',
        amount: data.transactionDetails[0].amount || '-',
        reference: data.transactionDetails[0].reference || '-',
    }
    return (
        <>
            <div className="lg:block hidden">
                <hr className="my-6 bg-[#D0D5DD] " />
                <div className="flex justify-between items-center">
                    <div className="">
                        <p className="text-base font-semibold text-[#344054]">
                            Payment confirmation
                        </p>
                        <p className="text-sm font-normal text-[#667085]">
                            Download Payment confirmation in PDF format.
                        </p>
                    </div>
                    <JncButton
                        icon="download"
                        text="Download"
                        onClickCall={() => generatePDF(paymentData)}
                    />
                </div>
            </div>
            <div className="lg:hidden">
                <div className="flex items-center justify-center flex-col">
                    <button
                        type="button"
                        onClick={() => generatePDF(paymentData)}
                        className={`jncRepeatBtn bg-black rounded-full`}
                    >
                        <Icon id="download" />
                    </button>
                    <span>Payment confirmation</span>
                </div>
            </div>
        </>
    )
}

export default JncPaymentConfirmation
